/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useEffect, useRef, useState } from 'react';
import { Space } from 'antd';
import '../GameList/gameList.css'
import { ChooseBetSoccer } from '../../../../../api/betting/bettingApi';
import { checkSelectSoccerMoreTabNew_V2, getChangeClass_V3, getChangeColorSoccer_V3 } from '../../../../../until';
import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import { Odd } from '../../../../../api/homePage/homePage_V2Api';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { bg_checked, checked } from '../../../../../components/imgExport';
interface Props {
  totalGame?: number
  totalGoal: any[]
  totalGoalOld?: Odd[]

  totalCornersOverUnder?: Odd[]
  totalCornersHandicap?: Odd[]
  totalCornersFHOverUnder?: Odd[]
  totalCornersFHHandicap?: Odd[]
  totalCornersOverUnderOld?: Odd[]
  totalCornersHandicapOld?: Odd[]
  totalCornersFHOverUnderOld?: Odd[]
  totalCornersFHHandicapOld?: Odd[]
  homeTeam: string
  awayTeam: string
  homeScore?: number
  awayScore?: number
  homeScoreTotalCorner?: number
  awayScoreTotalCorner?: number
  listChooseBet: ChooseBetSoccer[]
  listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
  chooseBetTop?: (
    order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
  ) => void
  chooseBetTopTotalCornersHandicap?: (
    order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
  ) => void

  chooseBetTopTotalCornersFHHandicap?: (
    order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
  ) => void

  chooseBetTopTotalCornersFHOU?: (
    order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
  ) => void

  chooseBetSoccerOEF?: (
    order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
  ) => void
  checkUpdateStrightBetsSlip?: (
    id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number
  ) => void
  updateOdds?: any[]
  dateFilterSoccer?: string
  listDateFilterSoccer?: homePage_V2Api.infoListDate[]
}
const StatusBarSoccerTotalCorners: React.FC<Props> = ({
  totalGoal, totalGoalOld,
  totalCornersOverUnder,
  totalCornersHandicap,
  totalCornersFHOverUnder,
  totalCornersFHHandicap,
  totalCornersOverUnderOld,
  totalCornersHandicapOld,
  totalCornersFHOverUnderOld,
  totalCornersFHHandicapOld,
  totalGame, homeTeam, awayTeam, listChooseBet, listAddStrightBetsSlipSoccer, homeScore, awayScore, dateFilterSoccer, listDateFilterSoccer,
  homeScoreTotalCorner, awayScoreTotalCorner,
  chooseBetTop, chooseBetTopTotalCornersHandicap, chooseBetTopTotalCornersFHHandicap,
  chooseBetTopTotalCornersFHOU, chooseBetSoccerOEF, checkUpdateStrightBetsSlip, updateOdds }) => {
  const oddTotalCornersOverUnder = useRef<any[]>([totalCornersOverUnder])
  const oddTotalCornersOverUnderOld = useRef<any[]>([totalCornersOverUnderOld])

  const oddTotalCornersHandicap = useRef<any[]>([totalCornersHandicap])
  const oddTotalCornersHandicapOld = useRef<any[]>([totalCornersHandicapOld])

  const oddTotalCornersFHOverUnder = useRef<any[]>([totalCornersFHOverUnder])
  const oddTotalCornersFHOverUnderOld = useRef<any[]>([totalCornersFHOverUnderOld])

  const oddTotalCornersFHHandicap = useRef<any[]>([totalCornersFHHandicap])
  const oddTotalCornersFHHandicapOld = useRef<any[]>([totalCornersFHHandicapOld])


  const updateArrayOdds = async () => {

    totalCornersOverUnder && totalCornersOverUnder.forEach(element => {
      updateOdds?.forEach(elementUpdateOdds => {
        if (element.id === elementUpdateOdds.id) {
          let a = oddTotalCornersOverUnder.current
          setTimeout(() => {
            const abc: any[] = oddTotalCornersOverUnder.current.map(item => {
              return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
            })
            oddTotalCornersOverUnder.current = abc
            oddTotalCornersOverUnderOld.current = a
          }, 500);
        }
      });
    });

    totalCornersHandicap && totalCornersHandicap.forEach(element => {
      updateOdds?.forEach(elementUpdateOdds => {
        if (element.id === elementUpdateOdds.id) {
          let a = oddTotalCornersHandicap.current
          setTimeout(() => {
            const abc: any[] = oddTotalCornersHandicap.current.map(item => {
              return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
            })
            oddTotalCornersHandicap.current = abc
            oddTotalCornersHandicapOld.current = a
          }, 500);
        }
      });
    });

    totalCornersFHOverUnder && totalCornersFHOverUnder.forEach(element => {
      updateOdds?.forEach(elementUpdateOdds => {
        if (element.id === elementUpdateOdds.id) {
          let a = oddTotalCornersFHOverUnder.current
          setTimeout(() => {
            const abc: any[] = oddTotalCornersFHOverUnder.current.map(item => {
              return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
            })
            oddTotalCornersFHOverUnder.current = abc
            oddTotalCornersFHOverUnderOld.current = a
          }, 500);
        }
      });
    });

    totalCornersFHHandicap && totalCornersFHHandicap.forEach(element => {
      updateOdds?.forEach(elementUpdateOdds => {
        if (element.id === elementUpdateOdds.id) {
          let a = oddTotalCornersFHHandicap.current
          setTimeout(() => {
            const abc: any[] = oddTotalCornersFHHandicap.current.map(item => {
              return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
            })
            oddTotalCornersFHHandicap.current = abc
            oddTotalCornersFHHandicapOld.current = a
          }, 500);
        }
      });
    });
    setItemStateTotalCornersOverUnder(oddTotalCornersOverUnder.current);
    setItemStateTotalCornersHandicap(oddTotalCornersHandicap.current);
    setItemStateTotalCornersFHOverUnder(oddTotalCornersFHOverUnder.current);
    setItemStateTotalCornersFHHandicap(oddTotalCornersFHHandicap.current);
    setTimeout(() => {
      oddTotalCornersOverUnderOld.current = oddTotalCornersOverUnder.current
      oddTotalCornersHandicapOld.current = oddTotalCornersHandicap.current
      oddTotalCornersFHOverUnderOld.current = oddTotalCornersFHOverUnder.current
      oddTotalCornersFHHandicapOld.current = oddTotalCornersFHHandicap.current
    }, 2000);
  }

  useEffect(() => {
    updateArrayOdds()
  }, [updateOdds])

  const [itemStateTotalCornersOverUnder, setItemStateTotalCornersOverUnder] = useState<any[]>([totalCornersOverUnder]);
  const [itemStateTotalCornersHandicap, setItemStateTotalCornersHandicap] = useState<any[]>([totalCornersHandicap]);
  const [itemStateTotalCornersFHOverUnder, setItemStateTotalCornersFHOverUnder] = useState<any[]>([totalCornersFHOverUnder]);
  const [itemStateTotalCornersFHHandicap, setItemStateTotalCornersFHHandicap] = useState<any[]>([totalCornersFHHandicap]);

  useEffect(() => {
    setItemStateTotalCornersOverUnder(totalCornersOverUnder ? totalCornersOverUnder : [])
    oddTotalCornersOverUnder.current = totalCornersOverUnder ? totalCornersOverUnder : []
    oddTotalCornersOverUnderOld.current = totalCornersOverUnderOld ? totalCornersOverUnderOld : []
  }, [totalCornersOverUnder])

  useEffect(() => {
    setItemStateTotalCornersHandicap(totalCornersHandicap ? totalCornersHandicap : [])
    oddTotalCornersHandicap.current = totalCornersHandicap ? totalCornersHandicap : []
    oddTotalCornersHandicapOld.current = totalCornersHandicapOld ? totalCornersHandicapOld : []
  }, [totalCornersHandicap])

  useEffect(() => {
    setItemStateTotalCornersFHOverUnder(totalCornersFHOverUnder ? totalCornersFHOverUnder : [])
    oddTotalCornersFHOverUnder.current = totalCornersFHOverUnder ? totalCornersFHOverUnder : []
    oddTotalCornersFHOverUnderOld.current = totalCornersFHOverUnderOld ? totalCornersFHOverUnderOld : []
  }, [totalCornersFHOverUnder])

  useEffect(() => {
    setItemStateTotalCornersFHHandicap(totalCornersFHHandicap ? totalCornersFHHandicap : [])
    oddTotalCornersFHHandicap.current = totalCornersFHHandicap ? totalCornersFHHandicap : []
    oddTotalCornersFHHandicapOld.current = totalCornersFHHandicapOld ? totalCornersFHHandicapOld : []
  }, [totalCornersFHHandicap])

  return (
    <>
      <div className='flex w-full gap-2'>
        {((itemStateTotalCornersOverUnder && itemStateTotalCornersOverUnder.length > 0) || (itemStateTotalCornersHandicap && itemStateTotalCornersHandicap.length > 0) || (itemStateTotalCornersFHOverUnder && itemStateTotalCornersFHOverUnder.length > 0) || (itemStateTotalCornersFHHandicap && itemStateTotalCornersFHHandicap.length > 0)) && (
          <div className='w-full flex flex-col NFLstatus'>
            <div className='flex items-center flex-col w-full'>
              <div className='bg-[#1E212B] rounded-4 flex flex-col mt-6 pr-[18px] rounded-[6px] soccerBar w-full p-2'>
                <div className='flex w-full justify-center gap-2 topStatus'>
                  <Space className=' justify-center flex items-center text-nowrap !text-[#E4E7F1] !text-sm !font-bold-GT-America'>
                    Total Corners
                  </Space>
                </div>
              </div>
              <div className='flex flex-col w-full mt-2'>
                <div className='flex w-full mt-2'>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      Event
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      HDP
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      OU
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      FH.HDP
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      FH.OU
                    </div>
                  </div>
                </div>
                <div className='flex w-full mt-2'>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      Home
                    </div>
                    {homeScoreTotalCorner !== undefined && (
                      <div className={`justify-center w-full flex items-center text-white`}>
                        {homeScoreTotalCorner}
                      </div>
                    )}
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      {itemStateTotalCornersHandicap && itemStateTotalCornersHandicap.length > 0 && itemStateTotalCornersHandicap[0].prices && itemStateTotalCornersHandicap[0].prices.length > 0
                        ?
                        <div className={`h-[40px] m-1 border border-[#202531]  flex flex-col justify-center items-center  relative hover:!border-[#D4B962] cursor-pointer w-full bg-[#20273D] rounded-md  
                                     ${getChangeColorSoccer_V3(oddTotalCornersHandicap.current?.[0]?.prices?.[1]?.price, oddTotalCornersHandicapOld.current?.[0]?.prices?.[1]?.price,
                          () => (listChooseBet && oddTotalCornersHandicap.current?.[0]?.prices?.[1]?.price !== oddTotalCornersHandicapOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemStateTotalCornersHandicap[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemStateTotalCornersHandicap[0].id)].option === itemStateTotalCornersHandicap?.[0]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemStateTotalCornersHandicap[0].id, (Number(itemStateTotalCornersHandicap[0]?.point) !== 0 ? (-itemStateTotalCornersHandicap[0]?.point) : "0") + " " + itemStateTotalCornersHandicap[0].prices[1].price, itemStateTotalCornersHandicap?.[0]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                          dateFilterSoccer,
                          listDateFilterSoccer
                        )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersHandicap?.[0]?.prices?.[1]?.option, 0, 0, itemStateTotalCornersHandicap[0]?.id ?? 0, listChooseBet, homeTeam, "Fulltime Total Corners HDP")}`}
                          onClick={() => chooseBetTopTotalCornersHandicap?.(
                            0, itemStateTotalCornersHandicap[0].id, homeTeam, (Number(itemStateTotalCornersHandicap[0]?.point) !== 0 ? (-itemStateTotalCornersHandicap[0]?.point) : "0") + " " + itemStateTotalCornersHandicap[0].prices[1].price, itemStateTotalCornersHandicap?.[0]?.prices?.[1]?.option
                          )}
                        >
                          <p className='text-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-xs'>{itemStateTotalCornersHandicap[0]?.point >= 0 ? -Math.abs(itemStateTotalCornersHandicap[0]?.point) : ""}</p>
                          <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemStateTotalCornersHandicap[0]?.prices[1]?.price).toFixed(2)}</b></p>
                          <CaretRightOutlined className={`${getChangeClass_V3(oddTotalCornersHandicap.current?.[0]?.prices?.[1]?.price, oddTotalCornersHandicapOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                          {checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersHandicap?.[0]?.prices?.[1]?.option, 0, 0, itemStateTotalCornersHandicap[0]?.id ?? 0, listChooseBet, homeTeam, "Fulltime Total Corners HDP").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                        </div>
                        :
                        <div className={`h-[40px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full !rounded-none`}>
                          <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      }
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      {itemStateTotalCornersOverUnder && itemStateTotalCornersOverUnder.length > 0 && itemStateTotalCornersOverUnder[0].prices && itemStateTotalCornersOverUnder[0].prices.length > 0
                        ?
                        <div className={`h-[40px] m-1 border border-[#202531]  flex flex-col justify-center items-center  relative hover:!border-[#D4B962] cursor-pointer w-full bg-[#20273D] rounded-md  
                                     ${getChangeColorSoccer_V3(oddTotalCornersOverUnder.current?.[0]?.prices?.[1]?.price, oddTotalCornersOverUnderOld.current?.[0]?.prices?.[1]?.price,
                          () => (listChooseBet && oddTotalCornersOverUnder.current?.[0]?.prices?.[1]?.price !== oddTotalCornersOverUnderOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemStateTotalCornersOverUnder[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemStateTotalCornersOverUnder[0].id)].option === itemStateTotalCornersOverUnder?.[0]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemStateTotalCornersOverUnder[0].id, itemStateTotalCornersOverUnder[0]?.point + "-Over " + itemStateTotalCornersOverUnder[0].prices[1].price, itemStateTotalCornersOverUnder?.[0]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                          dateFilterSoccer,
                          listDateFilterSoccer
                        )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersOverUnder?.[0]?.prices?.[1]?.option, 0, 0, itemStateTotalCornersOverUnder[0]?.id ?? 0, listChooseBet, homeTeam, "Fulltime Total Corners OU")}`}
                          onClick={() => chooseBetTop?.(
                            0, itemStateTotalCornersOverUnder[0].id, homeTeam, itemStateTotalCornersOverUnder[0]?.point + "-Over " + itemStateTotalCornersOverUnder[0].prices[1].price, itemStateTotalCornersOverUnder?.[0]?.prices?.[1]?.option
                          )}
                        >
                          <p className='text-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-xs'>{itemStateTotalCornersOverUnder[0]?.point} - O</p>
                          <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemStateTotalCornersOverUnder[0]?.prices[1]?.price).toFixed(2)}</b></p>
                          <CaretRightOutlined className={`${getChangeClass_V3(oddTotalCornersOverUnder.current?.[0]?.prices?.[1]?.price, oddTotalCornersOverUnderOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                          {checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersOverUnder?.[0]?.prices?.[1]?.option, 0, 0, itemStateTotalCornersOverUnder[0]?.id ?? 0, listChooseBet, homeTeam, "Fulltime Total Corners OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                        </div>
                        :
                        <div className={`h-[40px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full !rounded-none`}>
                          <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      }
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      {itemStateTotalCornersFHHandicap && itemStateTotalCornersFHHandicap.length > 0 && itemStateTotalCornersFHHandicap[0].prices && itemStateTotalCornersFHHandicap[0].prices.length > 0
                        ?
                        <div className={`h-[40px] m-1 border border-[#202531]  flex flex-col justify-center items-center  relative hover:!border-[#D4B962] cursor-pointer w-full bg-[#20273D] rounded-md  
                                     ${getChangeColorSoccer_V3(oddTotalCornersFHHandicap.current?.[0]?.prices?.[1]?.price, oddTotalCornersFHHandicapOld.current?.[0]?.prices?.[1]?.price,
                          () => (listChooseBet && oddTotalCornersFHHandicap.current?.[0]?.prices?.[1]?.price !== oddTotalCornersFHHandicapOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemStateTotalCornersFHHandicap[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemStateTotalCornersFHHandicap[0].id)].option === itemStateTotalCornersFHHandicap?.[0]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemStateTotalCornersFHHandicap[0].id, (Number(itemStateTotalCornersFHHandicap[0]?.point) !== 0 ? (-itemStateTotalCornersFHHandicap[0]?.point) : "0") + " " + itemStateTotalCornersFHHandicap[0].prices[1].price, itemStateTotalCornersFHHandicap?.[0]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                          dateFilterSoccer,
                          listDateFilterSoccer
                        )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersFHHandicap?.[0]?.prices?.[1]?.option, 0, 0, itemStateTotalCornersFHHandicap[0]?.id ?? 0, listChooseBet, homeTeam, "First Half Total Corners HDP")}`}
                          onClick={() => chooseBetTopTotalCornersFHHandicap?.(
                            0, itemStateTotalCornersFHHandicap[0].id, homeTeam, (Number(itemStateTotalCornersFHHandicap[0]?.point) !== 0 ? (-itemStateTotalCornersFHHandicap[0]?.point) : "0") + " " + itemStateTotalCornersFHHandicap[0].prices[1].price, itemStateTotalCornersFHHandicap?.[0]?.prices?.[1]?.option
                          )}
                        >
                          <p className='text-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-xs'>{itemStateTotalCornersFHHandicap[0]?.point >= 0 ? -Math.abs(itemStateTotalCornersFHHandicap[0]?.point) : ""}</p>
                          <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemStateTotalCornersFHHandicap[0]?.prices[1]?.price).toFixed(2)}</b></p>
                          <CaretRightOutlined className={`${getChangeClass_V3(oddTotalCornersFHHandicap.current?.[0]?.prices?.[1]?.price, oddTotalCornersFHHandicapOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                          {checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersFHHandicap?.[0]?.prices?.[1]?.option, 0, 0, itemStateTotalCornersFHHandicap[0]?.id ?? 0, listChooseBet, homeTeam, "First Half Total Corners HDP").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                        </div>
                        :
                        <div className={`h-[40px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full !rounded-none`}>
                          <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      }
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      {itemStateTotalCornersFHOverUnder && itemStateTotalCornersFHOverUnder.length > 0 && itemStateTotalCornersFHOverUnder[0].prices && itemStateTotalCornersFHOverUnder[0].prices.length > 0
                        ?
                        <div className={`h-[40px] m-1 border border-[#202531]  flex flex-col justify-center items-center  relative hover:!border-[#D4B962] cursor-pointer w-full bg-[#20273D] rounded-md  
                                     ${getChangeColorSoccer_V3(oddTotalCornersFHOverUnder.current?.[0]?.prices?.[1]?.price, oddTotalCornersFHOverUnderOld.current?.[0]?.prices?.[1]?.price,
                          () => (listChooseBet && oddTotalCornersFHOverUnder.current?.[0]?.prices?.[1]?.price !== oddTotalCornersFHOverUnderOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemStateTotalCornersFHOverUnder[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemStateTotalCornersFHOverUnder[0].id)].option === itemStateTotalCornersFHOverUnder?.[0]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemStateTotalCornersFHOverUnder[0].id, itemStateTotalCornersFHOverUnder[0]?.point + "-Over " + itemStateTotalCornersFHOverUnder[0].prices[1].price, itemStateTotalCornersFHOverUnder?.[0]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                          dateFilterSoccer,
                          listDateFilterSoccer
                        )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersFHOverUnder?.[0]?.prices?.[1]?.option, 0, 0, itemStateTotalCornersFHOverUnder[0]?.id ?? 0, listChooseBet, homeTeam, "First Half Total Corners OU")}`}
                          onClick={() => chooseBetTopTotalCornersFHOU?.(
                            0, itemStateTotalCornersFHOverUnder[0].id, homeTeam, itemStateTotalCornersFHOverUnder[0]?.point + "-Over " + itemStateTotalCornersFHOverUnder[0].prices[1].price, itemStateTotalCornersFHOverUnder?.[0]?.prices?.[1]?.option
                          )}
                        >
                          <p className='text-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-xs'>{itemStateTotalCornersFHOverUnder[0]?.point} - O</p>
                          <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemStateTotalCornersFHOverUnder[0]?.prices[1]?.price).toFixed(2)}</b></p>
                          <CaretRightOutlined className={`${getChangeClass_V3(oddTotalCornersFHOverUnder.current?.[0]?.prices?.[1]?.price, oddTotalCornersFHOverUnderOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                          {checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersFHOverUnder?.[0]?.prices?.[1]?.option, 0, 0, itemStateTotalCornersFHOverUnder[0]?.id ?? 0, listChooseBet, homeTeam, "First Half Total Corners OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                        </div>
                        :
                        <div className={`h-[40px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full !rounded-none`}>
                          <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className='flex w-full'>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      Away
                    </div>
                    {awayScoreTotalCorner !== undefined && (
                      <div className={`justify-center w-full flex items-center text-white`}>
                        {awayScoreTotalCorner}
                      </div>
                    )}

                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      {itemStateTotalCornersHandicap && itemStateTotalCornersHandicap.length > 0 && itemStateTotalCornersHandicap[0].prices && itemStateTotalCornersHandicap[0].prices.length > 0
                        ?
                        <div className={`h-[40px] m-1 border border-[#202531]  flex flex-col justify-center items-center  relative hover:!border-[#D4B962] cursor-pointer w-full bg-[#20273D] rounded-md  
                                     ${getChangeColorSoccer_V3(oddTotalCornersHandicap.current?.[0]?.prices?.[0]?.price, oddTotalCornersHandicapOld.current?.[0]?.prices?.[0]?.price,
                          () => (listChooseBet && oddTotalCornersHandicap.current?.[0]?.prices?.[0]?.price !== oddTotalCornersHandicapOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemStateTotalCornersHandicap[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemStateTotalCornersHandicap[0].id)].option === itemStateTotalCornersHandicap?.[0]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemStateTotalCornersHandicap[0].id, (Number(itemStateTotalCornersHandicap[0]?.point) !== 0 ? (-itemStateTotalCornersHandicap[0]?.point) : "0") + " " + itemStateTotalCornersHandicap[0].prices[0].price, itemStateTotalCornersHandicap?.[0]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                          dateFilterSoccer,
                          listDateFilterSoccer
                        )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersHandicap?.[0]?.prices?.[0]?.option, 0, 0, itemStateTotalCornersHandicap[0]?.id ?? 0, listChooseBet, awayTeam, "Fulltime Total Corners HDP")}`}
                          onClick={() => chooseBetTopTotalCornersHandicap?.(
                            0, itemStateTotalCornersHandicap[0].id, awayTeam, (Number(itemStateTotalCornersHandicap[0]?.point) !== 0 ? (itemStateTotalCornersHandicap[0]?.point) : "0") + " " + itemStateTotalCornersHandicap[0].prices[0].price, itemStateTotalCornersHandicap?.[0]?.prices?.[0]?.option
                          )}
                        >
                          <p className='text-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-xs'>{itemStateTotalCornersHandicap[0]?.point < 0 ? -Math.abs(itemStateTotalCornersHandicap[0]?.point) : ""}</p>
                          <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemStateTotalCornersHandicap[0]?.prices[0]?.price).toFixed(2)}</b></p>
                          <CaretRightOutlined className={`${getChangeClass_V3(oddTotalCornersHandicap.current?.[0]?.prices?.[0]?.price, oddTotalCornersHandicapOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                          {checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersHandicap?.[0]?.prices?.[0]?.option, 0, 0, itemStateTotalCornersHandicap[0]?.id ?? 0, listChooseBet, awayTeam, "Fulltime Total Corners HDP").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                        </div>
                        :
                        <div className={`h-[40px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full !rounded-none`}>
                          <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      }
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      {itemStateTotalCornersOverUnder && itemStateTotalCornersOverUnder.length > 0 && itemStateTotalCornersOverUnder[0].prices && itemStateTotalCornersOverUnder[0].prices.length > 0
                        ?
                        <div className={`h-[40px] m-1 border border-[#202531]  flex flex-col justify-center items-center  relative hover:!border-[#D4B962] cursor-pointer w-full bg-[#20273D] rounded-md  
                                     ${getChangeColorSoccer_V3(oddTotalCornersOverUnder.current?.[0]?.prices?.[0]?.price, oddTotalCornersOverUnderOld.current?.[0]?.prices?.[0]?.price,
                          () => (listChooseBet && oddTotalCornersOverUnder.current?.[0]?.prices?.[0]?.price !== oddTotalCornersOverUnderOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemStateTotalCornersOverUnder[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemStateTotalCornersOverUnder[0].id)].option === itemStateTotalCornersOverUnder?.[0]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemStateTotalCornersOverUnder[0].id, itemStateTotalCornersOverUnder[0]?.point + "-Under " + itemStateTotalCornersOverUnder[0].prices[0].price, itemStateTotalCornersOverUnder?.[0]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                          dateFilterSoccer,
                          listDateFilterSoccer
                        )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersOverUnder?.[0]?.prices?.[0]?.option, 0, 0, itemStateTotalCornersOverUnder[0]?.id ?? 0, listChooseBet, awayTeam, "Fulltime Total Corners OU")}`}
                          onClick={() => chooseBetTop?.(
                            0, itemStateTotalCornersOverUnder[0].id, awayTeam, itemStateTotalCornersOverUnder[0]?.point + "-Under " + itemStateTotalCornersOverUnder[0].prices[0].price, itemStateTotalCornersOverUnder?.[0]?.prices?.[0]?.option
                          )}
                        >
                          <p className='text-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-xs'>{itemStateTotalCornersOverUnder[0]?.point} - U</p>
                          <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemStateTotalCornersOverUnder[0]?.prices[0]?.price).toFixed(2)}</b></p>
                          <CaretRightOutlined className={`${getChangeClass_V3(oddTotalCornersOverUnder.current?.[0]?.prices?.[0]?.price, oddTotalCornersOverUnderOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                          {checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersOverUnder?.[0]?.prices?.[0]?.option, 0, 0, itemStateTotalCornersOverUnder[0]?.id ?? 0, listChooseBet, awayTeam, "Fulltime Total Corners OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                        </div>
                        :
                        <div className={`h-[40px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full !rounded-none`}>
                          <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      }
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      {itemStateTotalCornersFHHandicap && itemStateTotalCornersFHHandicap.length > 0 && itemStateTotalCornersFHHandicap[0].prices && itemStateTotalCornersFHHandicap[0].prices.length > 0
                        ?
                        <div className={`h-[40px] m-1 border border-[#202531]  flex flex-col justify-center items-center  relative hover:!border-[#D4B962] cursor-pointer w-full bg-[#20273D] rounded-md  
                                     ${getChangeColorSoccer_V3(oddTotalCornersFHHandicap.current?.[0]?.prices?.[0]?.price, oddTotalCornersFHHandicapOld.current?.[0]?.prices?.[0]?.price,
                          () => (listChooseBet && oddTotalCornersFHHandicap.current?.[0]?.prices?.[0]?.price !== oddTotalCornersFHHandicapOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemStateTotalCornersFHHandicap[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemStateTotalCornersFHHandicap[0].id)].option === itemStateTotalCornersFHHandicap?.[0]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemStateTotalCornersFHHandicap[0].id, (Number(itemStateTotalCornersFHHandicap[0]?.point) !== 0 ? (-itemStateTotalCornersFHHandicap[0]?.point) : "0") + " " + itemStateTotalCornersFHHandicap[0].prices[0].price, itemStateTotalCornersFHHandicap?.[0]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                          dateFilterSoccer,
                          listDateFilterSoccer
                        )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersFHHandicap?.[0]?.prices?.[0]?.option, 0, 0, itemStateTotalCornersFHHandicap[0]?.id ?? 0, listChooseBet, awayTeam, "First Half Total Corners HDP")}`}
                          onClick={() => chooseBetTopTotalCornersFHHandicap?.(
                            0, itemStateTotalCornersFHHandicap[0].id, awayTeam, (Number(itemStateTotalCornersFHHandicap[0]?.point) !== 0 ? (itemStateTotalCornersFHHandicap[0]?.point) : "0") + " " + itemStateTotalCornersFHHandicap[0].prices[0].price, itemStateTotalCornersFHHandicap?.[0]?.prices?.[0]?.option
                          )}
                        >
                          <p className='text-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-xs'>{itemStateTotalCornersFHHandicap[0]?.point < 0 ? -Math.abs(itemStateTotalCornersFHHandicap[0]?.point) : ""}</p>
                          <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemStateTotalCornersFHHandicap[0]?.prices[0]?.price).toFixed(2)}</b></p>
                          <CaretRightOutlined className={`${getChangeClass_V3(oddTotalCornersFHHandicap.current?.[0]?.prices?.[0]?.price, oddTotalCornersFHHandicapOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                          {checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersFHHandicap?.[0]?.prices?.[0]?.option, 0, 0, itemStateTotalCornersFHHandicap[0]?.id ?? 0, listChooseBet, awayTeam, "First Half Total Corners HDP").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                        </div>
                        :
                        <div className={`h-[40px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full !rounded-none`}>
                          <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      }
                    </div>
                  </div>
                  <div className={`flex hdp w-1/5`}>
                    <div className={`justify-center w-full flex items-center text-white`}>
                      {itemStateTotalCornersFHOverUnder && itemStateTotalCornersFHOverUnder.length > 0 && itemStateTotalCornersFHOverUnder[0].prices && itemStateTotalCornersFHOverUnder[0].prices.length > 0
                        ?
                        <div className={`h-[40px] m-1 border border-[#202531]  flex flex-col justify-center items-center  relative hover:!border-[#D4B962] cursor-pointer w-full bg-[#20273D] rounded-md  
                                     ${getChangeColorSoccer_V3(oddTotalCornersFHOverUnder.current?.[0]?.prices?.[0]?.price, oddTotalCornersFHOverUnderOld.current?.[0]?.prices?.[0]?.price,
                          () => (listChooseBet && oddTotalCornersFHOverUnder.current?.[0]?.prices?.[0]?.price !== oddTotalCornersFHOverUnderOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemStateTotalCornersFHOverUnder[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemStateTotalCornersFHOverUnder[0].id)].option === itemStateTotalCornersFHOverUnder?.[0]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemStateTotalCornersFHOverUnder[0].id, itemStateTotalCornersFHOverUnder[0]?.point + "-Under " + itemStateTotalCornersFHOverUnder[0].prices[0].price, itemStateTotalCornersFHOverUnder?.[0]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                          dateFilterSoccer,
                          listDateFilterSoccer
                        )} 
                                            ${checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersFHOverUnder?.[0]?.prices?.[0]?.option, 0, 0, itemStateTotalCornersFHOverUnder[0]?.id ?? 0, listChooseBet, awayTeam, "First Half Total Corners OU")}`}
                          onClick={() => chooseBetTopTotalCornersFHOU?.(
                            0, itemStateTotalCornersFHOverUnder[0].id, awayTeam, itemStateTotalCornersFHOverUnder[0]?.point + "-Under " + itemStateTotalCornersFHOverUnder[0].prices[0].price, itemStateTotalCornersFHOverUnder?.[0]?.prices?.[0]?.option
                          )}
                        >
                          <p className='text-[#E4E7F1] whitespace-nowrap xs:text-[10px] xl2:text-xs'>{itemStateTotalCornersFHOverUnder[0]?.point} - U</p>
                          <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemStateTotalCornersFHOverUnder[0]?.prices[0]?.price).toFixed(2)}</b></p>
                          <CaretRightOutlined className={`${getChangeClass_V3(oddTotalCornersFHOverUnder.current?.[0]?.prices?.[0]?.price, oddTotalCornersFHOverUnderOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                          {checkSelectSoccerMoreTabNew_V2(itemStateTotalCornersFHOverUnder?.[0]?.prices?.[0]?.option, 0, 0, itemStateTotalCornersFHOverUnder[0]?.id ?? 0, listChooseBet, awayTeam, "First Half Total Corners OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                        </div>
                        :
                        <div className={`h-[40px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full !rounded-none`}>
                          <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  );
};

export default StatusBarSoccerTotalCorners;