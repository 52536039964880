/* eslint-disable react-hooks/exhaustive-deps */
import Dropdown from "antd/es/dropdown/dropdown"
import { user_setting, edit, lock_open, Dollar, Statement_Grey, Statement_Gold } from "../imgExport"
import { MenuProps } from "antd";
import './style.css';
import { useEffect, useState } from "react";
import { ModalChangePassWord } from "./Modal/ModalChangePassWord";
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { ModalChangeUserName } from "./Modal/ModalChangeUserName";
import { useLocation } from "react-router-dom";
import * as loginApi from '../../api/login/loginApi';
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch } from 'react-redux';
import { clearAlert } from "../../store/slices/toastSlice";
import HeadRight from "../../page/homepage/components/MainNavi/Head/HeadRight";

export const HeaderTabNew: React.FC = () => {
    // const navigate = useNavigate()
    const location = useLocation();
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    const [username, setUsername] = useState<string>("Adam")
    const [openModalChangePass, setOpenModalChangePass] = useState<boolean>(false)
    const [openModalChangeUserName, setOpenModalChangeUserName] = useState<boolean>(false)
    const items: MenuProps['items'] = [
        {
            key: '1',
            type: 'group',
            label: <span className="text-sm text-[#C1C9E1]">Setting</span>,
            children: [
                {
                    key: '1-1',
                    label: <span
                        className="text-sm text-[#C1C9E1] hover:text-[#D4B962] flex items-center gap-3 pb-3"
                        onClick={() => setOpenModalChangeUserName(true)}
                    >
                        <img src={edit} alt="" />
                        Change username
                    </span>,
                },
                {
                    key: '1-2',
                    label: <span
                        className="text-sm text-[#C1C9E1] hover:text-[#D4B962] flex items-center gap-3"
                        onClick={() => setOpenModalChangePass(true)}
                    >
                        <img src={lock_open} alt="" />
                        Change password
                    </span>,
                },
            ],
        },
    ];

    const getUserInformation = async () => {
        pushLoading(true)
        const ketQua = await loginApi.getUserInformation()
        if (ketQua.success) {
            setUsername(ketQua.data.userName)
        } else {
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        pushLoading(false)
    }

    const handleClickNewTapMyBet = () => {
        // navigate('/mybet')
        window.open('/mybet', '_blank')
    }
    const handleClickNewTapStateMent = () => {
        // navigate('/statement')
        window.open('/statement', '_blank')
    }
    useEffect(() => {
        getUserInformation()
    }, [])

    return (
        <div className='xs:hidden sm:flex justify-between w-full border-b border-b-[#202531] fixed z-10 bg-[#131620] px-5'>
            <ModalChangePassWord
                isOpen={openModalChangePass}
                closeModal={() => setOpenModalChangePass(false)}
            />
            <ModalChangeUserName
                isOpen={openModalChangeUserName}
                closeModal={() => setOpenModalChangeUserName(false)}
            />
            <div className="w-[235px]">

            </div>
            <div className="flex items-center">
                <div className='border-r border-r-[#202531] h-[44px] flex justify-center items-center'>
                    <span className='text-sm text-[#C1C9E1] uppercase font-medium pr-[50px]'>hi, {username}</span>
                </div>
                <div className='border-r border-r-[#202531] h-[44px] flex justify-center items-center'>
                    <div className={`flex items-center gap-2 px-[50px] cursor-pointer text-sm uppercase font-medium text-[#C1C9E1] hover:text-[#D4B962] ${location.pathname === '/mybet' ? ' text-[#D4B962]' : ' text-[#C1C9E1]'}`}
                        onClick={handleClickNewTapMyBet}
                    >
                        <BookmarkIcon className='text-[24px]' />
                        my bet
                    </div>
                </div>
                <div className='border-r border-r-[#202531] h-[44px] flex justify-center items-center'>
                    <div className={`flex items-center gap-2 px-[50px] cursor-pointer text-sm uppercase font-medium text-[#C1C9E1] hover:text-[#D4B962] div-statement-hover ${location.pathname === '/statement' ? ' text-[#D4B962]' : ' text-[#C1C9E1]'}`}
                        onClick={handleClickNewTapStateMent}
                    >
                        {/* <NotificationsActiveIcon className='text-[24px]' /> */}
                        {location.pathname === '/statement' ?
                            <img src={Statement_Gold} alt="" className='h-[28px]' />
                            :
                            <>
                                <img src={Statement_Grey} alt="" className='h-[28px] block statement-hover-hidden' />
                                <img src={Statement_Gold} alt="" className='h-[28px] hidden statement-hover' />
                            </>
                        }

                        Statement
                    </div>
                </div>
                <Dropdown menu={{ items }} placement="bottomLeft" arrow={{ pointAtCenter: true }} >
                    <div className=' h-[44px] flex justify-center items-center cursor-pointer '>
                        <div className='flex items-center gap-2 px-[50px] text-sm  uppercase font-medium text-[#C1C9E1] '>
                            <img src={user_setting} alt="" />
                            setting
                        </div>
                    </div>
                </Dropdown>
            </div>

            <div className=' flex justify-between h-[44px] min-w-[235px] max-w-[355px] '>
                <div className='flex items-center gap-2 w-full'>
                    <img src={Dollar} alt="" className="w-[30px] h-[30px]" />
                    <span className='font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-sm text-white uppercase'>Balance</span>
                    <HeadRight />
                </div>
            </div>
        </div>
    )
}