import { paserParams } from "../../until";
import axiosClient, { ResponseSuccessType } from "../axiosClient";

export interface InfoUser {
    machineId: string
    money: string
    totalStake?: string
    presentMoney: string
    billCode: string
}

export interface InfoUser {
    id: string
    machineName: string
    authCode?: string
    currentMoney: string
}

//Load money in bill acceptor.
export const addLoadmoneyInBillAcceptor = async (machineId: string, money: string) => {
    const path = "api/v1/loadmoney"
    const params = {
        machineId, money
    }
    const result: ResponseSuccessType<InfoUser> = await axiosClient.get(
        path, { params: paserParams(params, undefined) }
    );
    return result;
}

//Refund money.
export const getRefundMoney = async (machineId: string, money: string) => {
    const path = "/api/v1/refundmoney"
    const params = {
        machineId, money
    }
    const result: ResponseSuccessType<InfoUser> = await axiosClient.get(
        path, { params: paserParams(params, undefined) }
    );
    return result;
}

export const getPresentMoney = async () => {
    const path = "/api/v1/present-money"
    const params = {}
    const result: ResponseSuccessType<InfoUser> = await axiosClient.get(
        path, { params: paserParams(params, undefined) }
    );
    return result;
}