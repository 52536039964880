import React, { useState } from "react";
// import { CloseOutlined } from "@mui/icons-material";
// import { Checkbox } from "antd";
import { InfoStrightBetsSlipTMP } from "../../../../../../api/betting/bettingApi";
import CaculateV2 from "../../../TotalStake/CaculateV2";
import { formatPrice, getPiceWinDecimal } from "../../../../../../until";
import { baketballActive, baseballActive, ElipseRed, NCAAF, rugbyActive, Trash } from "../../../../../../components/imgExport";
import './item.css'
interface Props {
    tab?: "homepage" | "MLB" | "NBA" | "NFL" | "NCAAF"
    index: number
    item: InfoStrightBetsSlipTMP
    listAddStrightBetsSlip: InfoStrightBetsSlipTMP[]
    rStrightBetsSlipByIndex: (value: number) => void
    setListaddStrightBetsSlip: (value: InfoStrightBetsSlipTMP[]) => void
    setTotalStake: (index: number) => void
    decimal?: string
}
const ItemStraight: React.FC<Props> = ({
    tab, index, item, listAddStrightBetsSlip, decimal,
    rStrightBetsSlipByIndex, setListaddStrightBetsSlip, setTotalStake
}) => {
    const [showStake, setShowStake] = useState(false);

    const handleClick = (e: number) => {
        if (item.amount) {
            // setTotalStake(totalStake + e)
            var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: (item.amount + e), win: getPiceWinDecimal(item.amount + e, item.scoreChoose, decimal) } : item));
            setListaddStrightBetsSlip(arr)
        } else {
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: e, win: getPiceWinDecimal(e, item.scoreChoose, decimal) } : item));
            setListaddStrightBetsSlip(arr)
        }
        setTotalStake(0)
    }
    const handleNumberClick = (e: number) => {
        if (item.amount) {
            // setTotalStake(totalStake + e)
            var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: (Number(`${item.amount}${e}`)), win: getPiceWinDecimal(Number(`${item.amount}${e}`), item.scoreChoose, decimal) } : item));
            setListaddStrightBetsSlip(arr)
        } else {
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: e, win: getPiceWinDecimal(e, item.scoreChoose, decimal) } : item));
            setListaddStrightBetsSlip(arr)
        }
        setTotalStake(0)
    }
    const handleDeleteClick = (e: number) => {
        var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, amount: e, win: "0" } : item));
        setListaddStrightBetsSlip(arr)
        setTotalStake(0)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCheck = (e: boolean) => {
        var arr = listAddStrightBetsSlip.map((item, indexc) => (indexc === index ? { ...item, isCheck: e } : item));
        setListaddStrightBetsSlip(arr)
    }


    return (
        <div className="border border-[#202531] rounded-[2px] p-3 flex flex-col">
            <div className="flex justify-between">
                <div className="flex justify-start pr-2">
                    {/* <div className={`${item.isWarning ? "" : "hidden"} flex items-center`}>
                        <InfoOutlined className="bg-[#ffc700] p-[1px] rounded-sm" />
                    </div> */}
                    <div>
                        <div className='flex justify-start pl-2 gap-2'>
                            <img src={tab === "MLB" ? baseballActive : (tab === "NBA" ? baketballActive : (tab === "NFL" ? rugbyActive : NCAAF))} alt="" />
                            <span className='text-base text-white'>{item?.lineBetting}</span>
                        </div>
                        <div className='flex justify-start pl-2'>
                            <span className='text-left text-base text-white'>{item.game}</span>
                        </div>

                    </div>
                </div>
            </div>

            <div className='flex justify-between' key={`Straight-${item}`}>
                <div>
                    {/* <Checkbox
                        checked={item.isCheck}
                        onChange={(e) => handleCheck(!item.isCheck)}
                    ></Checkbox> */}
                    <span className='text-base font-[700] text-white pl-2'>
                        {` ${item?.teamChoose} 
                        ${item?.scoreChoose.split(" ").length === 3 ?
                                (item?.scoreChoose.split(" ")[0] + " " + item?.scoreChoose.split(" ")[1]) :
                                item?.scoreChoose.split(" ").length === 2 ?
                                    item?.scoreChoose.split(" ")[0] : ""}
                        `}
                    </span>
                </div>
                <div className='flex items-center'>
                    {/* <span className={`pr-2 font-[500] text-white`}> */}
                    <span className={`pr-2 font-bold-GT-America text-base ${item?.color ? item?.color : "text-white"}`}>
                        {decimal !== 'American' ?
                            item?.scoreChoose.split(" ").length === 3 ? Number(item?.scoreChoose.split(" ")[2]).toFixed(2) : item?.scoreChoose.split(" ").length === 2 ? Number(item?.scoreChoose.split(" ")[1]).toFixed(2) : Number(item?.scoreChoose.split(" ")[0]).toFixed(2)
                            :
                            item?.scoreChoose.split(" ").length === 3 ? (Number(item?.scoreChoose.split(" ")[2]) > 0 ? `+${Number(item?.scoreChoose.split(" ")[2])}` : Number(item?.scoreChoose.split(" ")[2])) : item?.scoreChoose.split(" ").length === 2 ? (Number(item?.scoreChoose.split(" ")[1]) > 0 ? `+${Number(item?.scoreChoose.split(" ")[1])}` : Number(item?.scoreChoose.split(" ")[1])) : (Number(item?.scoreChoose.split(" ")[0]) > 0 ? `+${Number(item?.scoreChoose.split(" ")[0])}` : Number(item?.scoreChoose.split(" ")[0]))
                        }
                    </span>
                    <img src={Trash} alt=""
                        onClick={() => rStrightBetsSlipByIndex?.(index)}
                    />
                    {/* <CloseOutlined
                        className='text-[#898989]'
                        onClick={() => rStrightBetsSlipByIndex?.(index)}
                    /> */}
                </div>
            </div>
            <div className={`flex flex-col`}>
                {listAddStrightBetsSlip.length > 1 &&
                    <div className="flex justify-between items-end w-full">
                        {/* <span className='font-[500] text-white text-left text-base'>{item?.game}</span> */}
                        <div>
                            <div
                                className={`flex justify-between ${listAddStrightBetsSlip.length > 1 ? "" : "hidden"}`}
                                onClick={() => setShowStake(!showStake)}
                            >
                                <span className='whitespace-nowrap rounded-lg font-bold-GT-America text-base h-[32px] w-full stakeDetail text-white border-[1px] border-white pr-[5px] pl-[5px] pt-[4px] pb-[4px]' >
                                    {/* text-[#666E97] */}
                                    {item.amount === 0 ? '' : (<span className="">$</span>)}
                                    {item.amount === 0 ? 'Total Stake' : `${item?.amount} `}
                                </span>
                            </div>

                        </div>

                        <div
                            className={`flex justify-between ${listAddStrightBetsSlip.length > 1 ? "" : "hidden"}`}
                        >
                            <span className="flex pt-3 text-[#13px]">
                                <p className="text-[#33E49A]">Win </p>
                                <b className="pl-1 text-[#33E49A]">{item.win === "0" ? '$-.--' : `$${formatPrice(item?.win || 0)}`}</b>
                            </span>
                        </div>
                    </div>
                }
                <div className='flex justify-between items-center pl-6'>
                    {item?.live ? (
                        <span className='font-bold-GT-America flex pt-4'>
                            <span className='flex w-fit bg-[#ffcccc] px-2 rounded-md text-red-500'>
                                <img className="pr-2" src={ElipseRed} alt="ElipseRed" /> LIVE
                            </span>
                        </span>
                    ) : ''}
                </div>
            </div>


            <CaculateV2
                showTotalStake={showStake}
                handleClick={handleClick}
                handleNumberClick={handleNumberClick}
                handleDeleteClick={handleDeleteClick}
                handleSuccess={() => setShowStake(false)}
            />
        </div>
    )
}

export default ItemStraight;