import React from "react";
// import { CloseOutlined } from "@mui/icons-material";
import { InfoOutlined } from "@ant-design/icons";
// import { Checkbox } from "antd";
import { InfoParlayBetsSlipTMP } from "../../../../../../api/betting/bettingApi";
import { baketballActive, baseballActive, NCAAF, rugbyActive, Trash } from "../../../../../../components/imgExport";
import './item.css'
interface Props {
    tab?: "homepage" | "MLB" | "NBA" | "NFL" | "NCAAF"
    index: number
    item: InfoParlayBetsSlipTMP
    listParlayBetsSlip: InfoParlayBetsSlipTMP[]
    rStrightBetsSlipByIndex: (value: number) => void
    setListParlayBetsSlip?: (value: InfoParlayBetsSlipTMP[]) => void
    decimal?: string
}
const ItemParlay: React.FC<Props> = ({
    tab, index, item, listParlayBetsSlip, decimal,
    rStrightBetsSlipByIndex, setListParlayBetsSlip
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCheck = (e: boolean) => {
        var arr = listParlayBetsSlip.map((item, indexc) => (indexc === index ? { ...item, isCheck: e } : item));
        setListParlayBetsSlip?.(arr)
    }

    return (
        <>
            <div className="border border-[#202531] rounded-[2px] p-3 flex flex-col">
                <div className="flex w-full">
                    <div className="flex w-full">
                        <div className="w-full">
                            <div className='flex justify-between gap-2'>
                                <div className="flex w-full gap-2">
                                    <div className={`${item.isWarning ? "" : "hidden"} flex items-center`}>
                                        <InfoOutlined className="bg-[#ffc700] p-[1px] rounded-sm" />
                                    </div>
                                    <img src={tab === "MLB" ? baseballActive : (tab === "NBA" ? baketballActive : (tab === "NFL" ? rugbyActive : NCAAF))} alt="" />
                                    <span className='text-base text-white'>
                                        {item?.lineBetting}
                                    </span>
                                </div>

                                <img src={Trash} alt=""
                                    onClick={() => rStrightBetsSlipByIndex?.(index)}
                                />
                            </div>

                            {/* <div className='flex justify-start  gap-2'>
                                <img src={tab === "MLB" ? baseballActive : (tab === "NBA" ? baketballActive : (tab === "NFL" ? rugbyActive : NCAAF))} alt="" />
                                <span className='text-base text-white '>{item?.lineBetting}</span>
                            </div> */}
                            <div className='flex justify-start'>
                                <span className='text-base text-white text-start'>{item.game}</span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='flex justify-between' key={`Parlay-${index}`}>
                    <div className='flex justify-between'>
                        {/* <Checkbox
                        checked={item.isCheck}
                        onChange={(e) => handleCheck(!item.isCheck)}
                    ></Checkbox> */}
                        <span className='text-base text-white'>
                            {`  ${item?.teamChoose} ${item.scoreChoose.split(" ")[0]} `}
                        </span>
                        <span className='pl-1 text-base text-white whitespace-nowrap'>
                            {item.scoreChoose.split(' ')[1] ? `@ ${Number(item.scoreChoose.split(' ')[1]).toFixed(2)}` : ""}
                        </span>
                    </div>
                </div>


            </div>

            {/* <div>
                <div className='flex justify-between pb-2 pt-4' key={`Parlay-${index}`}>
                    <div>
                        <Checkbox
                            checked={item.isCheck}
                            onChange={(e) => handleCheck(!item.isCheck)}
                        ></Checkbox>
                        <span className='font-[700] text-white pl-2'>
                            {` ${item?.teamChoose} 
                        ${item?.scoreChoose.split(" ").length === 3 ?
                                    (item?.scoreChoose.split(" ")[0] + " " + item?.scoreChoose.split(" ")[1]) :
                                    item?.scoreChoose.split(" ").length === 2 ?
                                        item?.scoreChoose.split(" ")[0] : ""}
                        `}
                        </span>
                    </div>
                    <div className='flex'>
                        <span className={`pr-2 font-bold-GT-America ${item?.color ? item?.color : "text-white"}`}>
                            {decimal !== 'American' ?
                                item?.scoreChoose.split(" ").length === 3 ? Number(item?.scoreChoose.split(" ")[2]).toFixed(2) : item?.scoreChoose.split(" ").length === 2 ? Number(item?.scoreChoose.split(" ")[1]).toFixed(2) : Number(item?.scoreChoose.split(" ")[0]).toFixed(2)
                                :
                                item?.scoreChoose.split(" ").length === 3 ? (Number(item?.scoreChoose.split(" ")[2]) > 0 ? `+${Number(item?.scoreChoose.split(" ")[2])}` : Number(item?.scoreChoose.split(" ")[2])) : item?.scoreChoose.split(" ").length === 2 ? (Number(item?.scoreChoose.split(" ")[1]) > 0 ? `+${Number(item?.scoreChoose.split(" ")[1])}` : Number(item?.scoreChoose.split(" ")[1])) : (Number(item?.scoreChoose.split(" ")[0]) > 0 ? `+${Number(item?.scoreChoose.split(" ")[0])}` : Number(item?.scoreChoose.split(" ")[0]))
                            }
                        </span>
                        <CloseOutlined
                            className='text-[#898989]'
                            onClick={() => rStrightBetsSlipByIndex?.(index)}
                        />
                    </div>
                </div>
                <div className="flex-col">
                    <div className="flex justify-start">
                        <div className={`${item.isWarning ? "" : "hidden"} flex items-center pb-6`}>
                            <InfoOutlined className="bg-[#ffc700] p-[1px] rounded-sm" />
                        </div>
                        <div>
                            <div className='flex justify-start pl-2 pb-2'>
                                <span className='text-[#898989] text-base'>{item?.lineBetting}</span>
                            </div>
                            <div className='flex justify-start pl-2'>
                                <span className='font-[500] text-white text-base'>{item.game}</span>
                            </div>
                        </div>

                    </div>
                    {item?.live ? (
                        <span className='font-bold-GT-America flex pt-4 betClippers'>
                            <span className='flex w-fit bg-[#ffcccc] px-2 rounded-md text-red-500'>
                                <img className="pr-2" src={ElipseRed} alt="ElipseRed" /> LIVE
                            </span>
                        </span>
                    ) : ''}
                </div>

            </div> */}
        </>



    )
}

export default ItemParlay;