/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { baketball, baketballActive, baseball, baseballActive, logout, NCAAF, rugby, rugbyActive, soccer, soccerActice } from '../../../../../components/imgExport';
import * as homePageApi from '../../../../../api/homePage/homePageApi'
import * as loginApi from '../../../../../api/login/loginApi';
import { InfoAddStrightSoccerTmp } from '../../../../../api/betting/bettingApi';
import HeadTabs_V2 from './HeadTab_V2';
import tokenService from '../../../../../services/token';
export interface itemMenu {
    id: number;
    name: string;
    icon: string;
    iconActive?: string;
    href: string;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const itemMenu: itemMenu[] = [

    {
        id: 1,
        name: 'Basketball',
        href: '/NBA',
        icon: baketball,
        iconActive: baketball,
    },
    {
        id: 2,
        name: 'Football',
        href: '/NFL',
        icon: rugby,
        iconActive: rugby,
    },
    {
        id: 6,
        name: 'Baseball',
        href: '/MLB',
        icon: baseball,
        iconActive: baseball,
    },
    {
        id: 9,
        name: 'Soccer',
        href: '/Soccer',
        icon: soccer,
        iconActive: soccer,
    },

]
interface Props {
    openDrawer?: boolean
    closeDrawer?: (value: boolean) => void
    tournaments: itemMenu
    setTournaments: (value: itemMenu) => void
}
const HeadNavi_V2: React.FC<Props> = ({
    openDrawer,
    closeDrawer,
    tournaments,
    setTournaments
}) => {
    const [menu, setMenu] = useState<itemMenu[]>([])
    const [OTPCode, setOTPCode] = useState<string>("")
    const checkSelect = (value: string) => {
        return value === tournaments.name ? "!text-[#D4B962] " : "text-[#C1C9E1]"
    }

    const getAuthcodeByToken = async () => {
        const ketqua = await loginApi.getAuthcodeByToken()
        // if (ketqua?.authCode) {
        //   setOTPCode(ketqua.authCode)
        // }
    }

    // viewSettings
    const viewSettings = async () => {
        const ketqua = await homePageApi.viewSettings()
        setMenu([{
            id: 0,
            name: 'Soccer',
            href: '/Soccer_V2',
            icon: soccer,
            iconActive: soccerActice,
        }])
        if (ketqua?.success) {
            if (ketqua.data.navigation.NBA === 1) {
                setMenu((data: any) => [...data, {
                    id: 1,
                    name: 'Basketball',
                    href: '/NBA',
                    icon: baketball,
                    iconActive: baketballActive,
                }])
            }
            if (ketqua.data.navigation.NFL === 1) {
                setMenu((data: any) => [...data, {
                    id: 2,
                    name: 'Football',
                    href: '/NFL',
                    icon: rugby,
                    iconActive: rugbyActive,
                }])
            }
            if (ketqua.data.navigation.MLB === 1) {
                setMenu((data: any) => [...data, {
                    id: 6,
                    name: 'Baseball',
                    href: '/MLB',
                    icon: baseball,
                    iconActive: baseballActive,
                }])
            }
            if (ketqua.data.navigation.NCAAF === 1) {
                setMenu((data: any) => [...data, {
                    id: 3,
                    name: 'NCAAF',
                    href: '/NCAAF',
                    icon: NCAAF,
                    iconActive: NCAAF,
                }])
            }
            // if (ketqua.data.navigation.SOC === 1) {
            //   setMenu((data: any) => [...data, {
            //     id: 9,
            //     name: 'Soccer',
            //     href: '/Soccer',
            //     icon: Soccer
            //   }])
            // }
            // if (ketqua.data.navigation.NCAAF === 1) {
            //   setMenu((data: any) => [...data, {
            //     id: 3,
            //     name: 'NCAAF',
            //     href: '/NCAAF',
            //     icon: NCAAF
            //   }])
            // }


            // if (ketqua.data.navigation.SOC === 1) {
            //   setMenu((data: any) => [...data, {
            //     id: 10,
            //     name: 'Soccer',
            //     href: '/Soccer_V2',
            //     icon: Soccer
            //   }])
            // }

        }
    }

    useEffect(() => {
        viewSettings()
        getAuthcodeByToken()
    }, [])
    return (
        <div className="flex xl:flex-col xs:flex-row justify-between xs:items-center fixed top-0 left-0 z-50  bg-[#131620]  xs:h-[76px] xs:w-full xl:h-[100vh] xl:w-[110px] xs:border-b xs:border-b-[#202531] xl:border-0">
            <div className="xs:w-full xl:w-auto">
                <HeadTabs_V2
                    openDrawer={openDrawer}
                    menu={menu}
                    tournaments={tournaments}
                    setTournaments={setTournaments}
                    closeDrawer={() => closeDrawer}

                />
                <div className='xs:hidden xl:flex justify-between items-center flex-col w-[109px] h-[calc(100vh-45px)] bg-none top-[45px]  border-t-0  border-r border-r-[#202531]'>
                    <div className='w-full xs:flex xl:block'>
                        {menu.map((items) => (
                            <div key={items.id} className='py-6 border-b border-b-[#202531] w-full cursor-pointer div-hover-sidebar'>
                                <div
                                    key={items.id}
                                    className=' flex justify-start items-center py-[17px] xs:px-5 xs:pl-0 xl:pl-1 xl2:pl-2 '
                                    onClick={() => setTournaments(items)}
                                >
                                    <div className='flex items-center '>
                                        {items.name === tournaments.name ?
                                            <img src={items.iconActive} alt="" />
                                            :
                                            <>
                                                <img src={items.icon} alt="" className='block img-hover-hidden' />
                                                <img src={items.iconActive} alt="" className='hidden img-hover' />
                                            </>


                                        }

                                        <span className={`xs:text-sm lg:text-xs xl:text-xs xl2:text-sm uppercase fw-[500] pl-2 ${checkSelect(items.name)}`}>{items.name}</span>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>

                    <button className='xs:hidden xl:flex border-t border-t-[#202531] h-[56px]  justify-center items-center gap-3 w-full '>
                        <img src={logout} alt="" />
                        <span className='text-sm font-medium text-[#C1C9E1]'
                            onClick={() => {
                                tokenService.removeToken()
                                window.location.href = "/login";
                            }}>
                            LOGOUT
                        </span>
                    </button>
                </div>

                <div className='text-[#222222] pr-5'>
                    {OTPCode}
                </div>
            </div>

        </div>
    )

}
export default HeadNavi_V2;