import React, { useEffect, useState } from 'react';
import { Button, Flex } from 'antd';
import { Home, MLB, NBA, NCAAF, NFL, Soccer } from '../../../../../components/imgExport';
import * as homePageApi from '../../../../../api/homePage/homePageApi';
import * as loginApi from '../../../../../api/login/loginApi';

const tabsNavi: React.CSSProperties = {
  minHeight: '46px',
  maxWidth: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '10px',
  backgroundColor: '#000000',
  paddingLeft: '20px'
};

const buttonStyle = {
  justifyContent: 'center',
  color: '#FFFFFF',
  fontWeight: 'bold',
};


export interface itemMenu {
  id: number;
  name: string;
  icon: string;
  href: string;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const itemMenu: itemMenu[] = [
  {
    id: 0,
    name: 'Home',
    href: '/',
    icon: Home
  },
  {
    id: 1,
    name: 'Basketball',
    href: '/NBA',
    icon: NBA
  },
  {
    id: 2,
    name: 'Football',
    href: '/NFL',
    icon: NFL
  },
  {
    id: 6,
    name: 'Baseball',
    href: '/MLB',
    icon: MLB
  },
  {
    id: 9,
    name: 'Soccer',
    href: '/Soccer',
    icon: Soccer
  },
  {
    id: 3,
    name: 'NCAAF',
    href: '/NCAAF',
    icon: NCAAF
  }
]
interface Props {
  tournaments: itemMenu
  setTournaments: (value: itemMenu) => void
}

const TabsNaviV2: React.FC<Props> = ({
  tournaments,
  setTournaments
}) => {
  const [menu, setMenu] = useState<itemMenu[]>([])
  const [OTPCode, setOTPCode] = useState<string>("")
  const checkSelect = (value: string) => {
    return value === tournaments.name ? "!bg-[#330000] !border-[#BB271A]" : ""
  }

  const getAuthcodeByToken = async () => {
    const ketqua = await loginApi.getAuthcodeByToken()
    // if (ketqua?.authCode) {
    //   setOTPCode(ketqua.authCode)
    // }
  }

  // viewSettings
  const viewSettings = async () => {
    const ketqua = await homePageApi.viewSettings()
    setMenu([{
      id: 0,
      name: 'Home',
      href: '/',
      icon: Home
    }])
    if (ketqua?.success) {
      if (ketqua.data.navigation.NBA === 1) {
        setMenu((data: any) => [...data, {
          id: 1,
          name: 'NBA',
          href: '/NBA',
          icon: NBA
        }])
      }
      if (ketqua.data.navigation.NFL === 1) {
        setMenu((data: any) => [...data, {
          id: 2,
          name: 'NFL',
          href: '/NFL',
          icon: NFL
        }])
      }
      if (ketqua.data.navigation.MLB === 1) {
        setMenu((data: any) => [...data, {
          id: 6,
          name: 'MLB',
          href: '/MLB',
          icon: MLB
        }])
      }
      // if (ketqua.data.navigation.SOC === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 9,
      //     name: 'Soccer',
      //     href: '/Soccer',
      //     icon: Soccer
      //   }])
      // }
      if (ketqua.data.navigation.NCAAF === 1) {
        setMenu((data: any) => [...data, {
          id: 3,
          name: 'NCAAF',
          href: '/NCAAF',
          icon: NCAAF
        }])
      }


      if (ketqua.data.navigation.SOC === 1) {
        setMenu((data: any) => [...data, {
          id: 10,
          name: 'Soccer',
          href: '/Soccer_V2',
          icon: Soccer
        }])
      }

    }
  }

  useEffect(() => {
    viewSettings()
    getAuthcodeByToken()
  }, [])

  return (
    <Flex className="tabsNavi !pr-[50px]" style={tabsNavi} wrap="wrap" vertical>
      <div className='flex justify-between items-center'>
        <div className='flex'>
          {
            menu.map((item) => (
              <Button
                key={item.id}
                className={`mr-1 ml-1 !flex items-center !bg-transparent ${checkSelect(item.name)}`}
                style={buttonStyle}
                icon={<img src={item.icon}
                  alt={item.name}></img>}
                onClick={() => setTournaments(item)}
              >{item.name}
              </Button>
            ))}
        </div>
        <div className='text-[#222222]'>
          {OTPCode}
        </div>
      </div>

    </Flex>
  )
};

export default React.memo(TabsNaviV2);