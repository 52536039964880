import { KB_best_sport_img, baketball, baseball, logoKB_2, rugby, soccer } from "../../components/imgExport";
import './style.css'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Form, Input } from "antd";
import { Link } from "react-router-dom";
import tokenService from '../../services/token';
import * as LoginApi from '../../api/login/loginApi';
import { useEffect, useState } from "react";
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch } from 'react-redux';
import ToastGlobal from "../../components/ToastGlobal";
import { clearAlert } from '../../store/slices/toastSlice';
import * as loginApi from '../../api/login/loginApi';
import { setUser } from "../../store/slices/userSlice";

const Login: React.FC = () => {
  const pushToast = useToast();
  const pushLoading = useLoading();
  const dispatch = useDispatch();

  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [ipDevice, setIpDevice] = useState<any>("");
  const onFinish = (values: any) => {
  };

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(async (data) => {
        setIpDevice(String(data.ip))
      })
      .catch(error =>
        console.log("Can't get IP and Location from device")
      )
  }, [])

  const handleLogin = async () => {
    pushLoading(true)
    if (username.length < 6) {
      dispatch(clearAlert())
      pushToast("UserName length must be at least 6 characters long", "warn");
      pushLoading(false)
      return
    }
    // postData();
    const ketQua = await LoginApi.loginByUsername(username, password, ipDevice)
    if (ketQua?.success) {
      dispatch(
        setUser({
          userId: ketQua.data?.id ? ketQua.data?.id?.toString() : "0",
          presentMoney: ketQua.data?.currentMoney ? ketQua.data?.currentMoney?.toString() : "0",
          authCode: "000000",
          machineCode: ketQua.data?.id ? ketQua.data?.id?.toString() : "0",
        })
      )
      tokenService.setToken(ketQua?.data?.token)
      if (Number(ketQua?.data?.firstLogin) === 1) {
        window.location.href = "/firstlogin"
      } else {
        window.location.href = "/Soccer_V2"
      }
    } else {
      dispatch(clearAlert())
      pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
      pushLoading(false)
      return
    }
    // 
    pushLoading(false)
  }

  const getAuthcodeByToken = async () => {
    const ketQua = await loginApi.getAuthcodeByToken()
    if (ketQua?.id) {
      window.location.href = "/Soccer_V2"
    }
  }

  useEffect(() => {
    getAuthcodeByToken()
  }, [])

  return (
    <div className='h-[100vh] !w-full flex  !justify-center !items-center bg-login-new'>
      <div className='flex flex-col  xs:w-[343px] xs:gap-0 sm:w-[343px] sm:gap-0 md:w-[694px] md:gap-4 lg:w-[694px] lg:gap-4 items-center rounded-[24px] border border-[#454449] bg-[#14171F]'>
        <div className='flex flex-col items-center gap-6 '>
          <div className="xs:pt-[24px] sm:pt-[24px] md:pt-[44px] lg:pt-[44px]">
            <img src={logoKB_2} alt=""
              className="xs:w-[60px] xs:h-[60px] sm:w-[60px] sm:h-[60px] md:w-[120px] md:h-[120px] lg:w-[120px] lg:h-[120px]"
            />
          </div>
          <img src={KB_best_sport_img} alt="" />
          <div className='flex gap-3'>
            <div className='xs:w-[40px] xs:gap-1 sm:w-[40px] sm:gap-1 md:w-[80xp] lg:w-[80px] h-[56px] flex flex-col items-center '>
              <img src={soccer} alt=""
                className="xs:w-[12px] xs:h-[12px] sm:w-[12px] sm:h-[12px] md:w-5 md:h-5 lg:w-5 lg:h-5 "
              />
              <span className='uppercase xs:text-xs sm:text-xs md:text-sm lg:text-sm fw-[500] text-[#E4E7F1]'>soccer</span>
            </div>
            <div className='xs:w-[40px] xs:gap-1 sm:w-[40px] sm:gap-1 md:w-[80xp] lg:w-[80px] h-[56px] flex flex-col items-center '>
              <img src={baketball} alt=""
                className="xs:w-[12px] xs:h-[12px] sm:w-[12px] sm:h-[12px] md:w-5 md:h-5 lg:w-5 lg:h-5 "
              />
              <span className='uppercase xs:text-xs sm:text-xs md:text-sm lg:text-sm fw-[500] text-[#E4E7F1]'>NBA</span>
            </div>
            <div className='xs:w-[40px] xs:gap-1 sm:w-[40px] sm:gap-1 md:w-[80xp] lg:w-[80px] h-[56px] flex flex-col items-center '>
              <img src={rugby} alt=""
                className="xs:w-[12px] xs:h-[12px] sm:w-[12px] sm:h-[12px] md:w-5 md:h-5 lg:w-5 lg:h-5 "
              />
              <span className='uppercase xs:text-xs sm:text-xs md:text-sm lg:text-sm fw-[500] text-[#E4E7F1]'>NFL</span>
            </div>
            <div className='xs:w-[40px] xs:gap-1 sm:w-[40px] sm:gap-1 md:w-[80xp] lg:w-[80px] h-[56px] flex flex-col items-center '>
              <img src={baseball} alt=""
                className="xs:w-[12px] xs:h-[12px] sm:w-[12px] sm:h-[12px] md:w-5 md:h-5 lg:w-5 lg:h-5 "
              />
              <span className='uppercase xs:text-xs sm:text-xs md:text-sm lg:text-sm fw-[500] text-[#E4E7F1]'>MLB</span>
            </div>
          </div>
        </div>
        <ToastGlobal />
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="xs:w-[279px] sm:w-[279px] md:w-[356px] lg:w-[356px]"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
            className="custom-input rounded-lg border-4B5563"
          >
            <Input
              prefix={<AccountCircleIcon className="text-[#C1C9E1] xs:!text-xs sm:!text-xs md:!text-2xl lg:!text-2xl" />}
              placeholder="Your username"
              size="large"
              className="!text-[#C1C9E1] placehoder-C1C9E1"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
            className="custom-input rounded-lg border-4B5563"
          >
            <Input.Password
              prefix={<LockIcon className="text-[#C1C9E1] xs:!text-xs sm:!text-xs md:!text-2xl lg:!text-2xl" />}
              type="password"
              placeholder="Your password"
              size="large"
              className="!text-[#C1C9E1] placehoder-C1C9E1"
              iconRender={(visible) => (visible ?
                <RemoveRedEyeIcon className="text-[#C1C9E1] " /> :
                <VisibilityOffIcon className="text-[#C1C9E1] " />)}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <div className="flex flex-col gap-6 items-center w-full xs:pb-6 sm:pb-6 md:pb-[59px] lg:pb-[59px] ">
            <button
              className="xs:text-xs  sm:text-xs md:text-sm lg:text-sm  bg-[#E1C467] text-[#131620] rounded-lg py-[14px] w-full font-bold-GT-America uppercase" type="submit"
              onClick={() => handleLogin()}
            >
              Login Now
            </button>
            <Link to="/forgot" className="xs:text-xs sm:text-xs md:text-[21px] lg:text-[21px] !text-[#E4E7F1] fw-[500] uppercase">forgot account?</Link>
          </div>
        </Form>
      </div>

    </div>
  )
}




export default Login;