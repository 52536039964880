/* eslint-disable react-hooks/exhaustive-deps */
import HeadNavi from "../homepage/components/MainNavi/Head/HeadNavi";
import { useEffect, useState } from "react";
import { itemMenu } from "../homepage/components/MainNavi/Tabs/TabsNaviV2";
import { soccerActice, soccer, baketballActive, baketball, rugbyActive, rugby, baseballActive, baseball, Dollar, calendar, TransactionWhite } from "../../components/imgExport";
import { SoccerStatement } from "./Component/SoccerStatement";
import { Layout } from "../../routes/Layout";
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert } from '../../store/slices/toastSlice';
import * as myBetApi from '../../api/myBet/myBetApi';
import './style.css'
import useThrottle from "../../hook/useThrottle";
import dayjs, { Dayjs } from "dayjs";
import { Button, DatePicker, TimeRangePickerProps, Typography } from "antd";
import { getPresentMoney } from "../../api/billAcceptor/billAcceptorApi";
import { selectorUser, setUserMoney } from "../../store/slices/userSlice";
import { formatPrice } from "../../until";
import HeadNavi_V2 from "../homepage/components/MainNavi/Head/HeadNavi_V2";
import { FooterMobile } from "../../components/FooterMobile";
const { RangePicker } = DatePicker;
export const Statement: React.FC = () => {
    const { presentMoney } = useSelector(selectorUser);
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    var startDateFirst = new Date(dayjs().startOf('week').toString()).getFullYear().toString() + "-" + (new Date(dayjs().startOf('week').toString()).getMonth() + 1 > 9 ? new Date(dayjs().startOf('week').toString()).getMonth() + 1 : "0" + new Date(dayjs().startOf('week').toString()).getMonth() + 1) + "-" + (new Date(dayjs().startOf('week').toString()).getDate() > 9 ? new Date(dayjs().startOf('week').toString()).getDate() : "0" + new Date(dayjs().startOf('week').toString()).getDate())
    var endDateFirst = new Date(dayjs().toString()).getFullYear().toString() + "-" + (new Date(dayjs().toString()).getMonth() + 1 > 9 ? new Date(dayjs().toString()).getMonth() + 1 : "0" + new Date(dayjs().toString()).getMonth() + 1) + "-" + (new Date(dayjs().toString()).getDate() > 9 ? new Date(dayjs().toString()).getDate() : "0" + new Date(dayjs().toString()).getDate())

    const [tournaments, setTournaments] = useState(itemMenu[0])
    const [chooseGame, setChooseGame] = useState<string>("Soccer")
    const [startDate, setStartDate] = useState<string>(startDateFirst)
    const [endDate, setEndDate] = useState<string>(endDateFirst)
    const [limit, setLimit] = useState<number>(20)
    const [offset, setOffset] = useState<number>(0)
    const [listBill, setListBill] = useState<any>([])

    const [money, setMoney] = useState("")
    const [time, setTime] = useState(0)
    const [openDrawerBestSlip, setOpenDrawerBestSlip] = useState(false)

    const handelChooseTournaments = (value: itemMenu) => {
        const listParent = document.querySelectorAll(".ant-menu-parent-tournament.ant-menu-submenu-selected")
        if (listParent.length > 0) {
            listParent[0].classList.remove("ant-menu-submenu-selected")
        }

        const listChildren = document.querySelectorAll(".ant-menu-children-tournament.ant-menu-item-selected")
        if (listChildren.length > 0) {
            listChildren[0].classList.remove("ant-menu-item-selected")
        }

        setTournaments(value)
        if (value.id !== 9) {
            setTournaments(value)
            // gListGameByTournament(value.id)
            // gListAllBetMGM(value.name)
        }
        if (value.id === 0) {
            // gListOfGamesForTheHomepage()
        }
        window.location.href = value.href
        // navigator(value.href)
    }

    const getCurrentMoney = useThrottle(async () => {
        pushLoading(true);
        var timeTotal = 10
        const interval = setInterval(() => {
            if (timeTotal > 0) {
                timeTotal -= 1
                setTime(timeTotal)
            } else {
                clearInterval(interval)
            }
        }, 1000);
        const ketQua = await getPresentMoney();
        if (ketQua?.success) {
            dispatch(setUserMoney(ketQua.data.currentMoney))
            setMoney(ketQua.data.currentMoney)
        }
        setTimeout(() => {
            pushLoading(false);
        }, 1000)
    }, 10000)

    const getMyBetRunning = useThrottle(async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myBetStatement(chooseGame, timeZone, limit, offset, startDate, endDate)
        if (ketQua?.success) {
            setListBill(ketQua.data)
        } else {
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }, 100)

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setStartDate && setStartDate(dateStrings[0])
            setEndDate && setEndDate(dateStrings[1])
        } else {
        }
    };
    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'This day', value: [dayjs('presets'), dayjs()] },
        { label: 'This week', value: [dayjs().startOf('week'), dayjs()] },
        { label: 'Last week', value: [dayjs().startOf('week').add(-7, 'd'), dayjs().endOf('week').add(-7, 'd')] },
        { label: 'Past 7 Day', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'This month', value: [dayjs().startOf('month'), dayjs()] },
        { label: 'Last month', value: [dayjs().startOf('month').add(-30, 'd'), (dayjs().startOf('month').add(-30, 'd')).endOf('month')] },
    ];

    useEffect(() => {
        getCurrentMoney()
    }, [chooseGame])

    useEffect(() => {
        getMyBetRunning()
    }, [startDate, endDate, chooseGame])

    return (
        <Layout>
            <div className='bg-[#131620]  overflow-hidden !h-[100vh] w-full xs:pt-[30px] xl:pt-0'>
                <HeadNavi_V2
                    openDrawer={openDrawerBestSlip}
                    closeDrawer={() => setOpenDrawerBestSlip(false)}
                    tournaments={tournaments}
                    setTournaments={handelChooseTournaments}
                />
                <div className="w-full h-full xs:mt-[55px] xl:mt-0 ">
                    <div className="w-full  xs:pl-0 xl:pl-[115px]  flex h-full">
                        <div className="flex flex-col w-full pl-1 pr-1 xs:pt-1 xl:pt-[55px]">
                            <div className="w-full ">
                                <div className="flex xs:flex-col xl:flex-row xl:justify-between items-center w-full border-l border-l-[#282C38] border-r border-r-[#282C38] border-t border-t-[#282C38] xs:px-2 xl:px-6">
                                    <div className="flex justify-between xs:pt-2 xl:pt-0 xs:w-full xl:w-auto">
                                        <div className="flex items-center rounded-lg border border-[#4B5563] px-4 ">
                                            <img src={calendar} alt="" />
                                            <RangePicker
                                                presets={rangePresets}
                                                onChange={onRangeChange}
                                                size="small"
                                                style={{ width: '220px' }}
                                                suffixIcon={false}
                                                defaultValue={[dayjs().startOf('week'), dayjs()]}
                                                value={[dayjs(startDate), dayjs(endDate)]}
                                                variant="borderless"
                                                className="custom-picker"
                                                popupClassName="custom-popup-picker"
                                                renderExtraFooter={() => <div className="flex w-full gap-4 pb-4">
                                                    <button className="w-full bg-[#131620] rounded-lg border border-[#C1C9E1] text-[#C1C9E1] text-xs font-medium py-2">
                                                        Cancel
                                                    </button>
                                                    <button className="w-full bg-[#D4B962] rounded-lg   text-[#FFFFFF] text-xs font-medium py-2">
                                                        OK
                                                    </button>
                                                </div>
                                                }
                                            />
                                        </div>
                                        <div className="xs:flex xl:hidden justify-end px-2 rounded-md w-full ">
                                            <div className="pl-1 cursor-pointer h-10 text-white flex items-center gap-2" onClick={() => window.location.reload()}>
                                                <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />
                                                <span className='text-xs !uppercase !text-white !m-0'>
                                                    Refresh
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center xs:justify-between md:justify-center py-6  xs:gap-0 md:gap-[52px] xl:gap-0">
                                        <div className={`flex items-center gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm xs:pr-0  xl2:pr-[26px] xl3:pr-[51px] text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Soccer' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                            onClick={() => { setChooseGame && setChooseGame('Soccer'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                        >
                                            <img src={` ${chooseGame === 'Soccer' ? soccerActice : soccer}`} alt="" className="xs:pl-0 xl:pl-[51px]  block img-hover-hidden"
                                            />
                                            <img src={`${soccerActice}`} alt="" className="xs:pl-0 xl:pl-[51px] hidden img-hover" />
                                            soccer

                                        </div>
                                        <div className={`flex items-center gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm  xs:pr-0  xl:pr-[26px] xl3:pr-[51px] text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hoverhover ${chooseGame === 'Basketball' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                            onClick={() => { setChooseGame && setChooseGame('Basketball'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                        >
                                            <img src={`${chooseGame === 'Basketball' ? baketballActive : baketball}`} alt="" className="xs:pl-0 xl:pl-[51px] block img-hover-hidden" />
                                            <img src={`${baketballActive}`} alt="" className="xs:pl-0 xl:pl-[51px] hidden img-hover" />
                                            basketball

                                        </div>
                                        <div className={`flex items-center gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm  xs:pr-0  xl:pr-[26px] xl3:pr-[51px] text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hoverhover ${chooseGame === 'Football' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                            onClick={() => { setChooseGame && setChooseGame('Football'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                        >
                                            <img src={`${chooseGame === 'Football' ? rugbyActive : rugby}`} alt="" className="xs:pl-0 xl:pl-[51px] block img-hover-hidden" />
                                            <img src={`${rugbyActive}`} alt="" className="xs:pl-0 xl:pl-[51px] hidden img-hover" />
                                            football

                                        </div>
                                        <div className={`flex items-center gap-2 uppercase xs:text-xs md:text-sm text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Baseball' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                            onClick={() => { setChooseGame && setChooseGame('Baseball'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                        >
                                            <img src={`${chooseGame === 'Baseball' ? baseballActive : baseball}`} alt="" className="xs:pl-0 xl:pl-[51px] block img-hover-hidden" />
                                            <img src={`${baseballActive}`} alt="" className="xs:pl-0 xl:pl-[51px] hidden img-hover" />
                                            baseball
                                        </div>
                                    </div>
                                    <div className="xs:hidden xl:flex justify-end px-2 rounded-md xs:w-full xl:w-auto xl:min-w-[300px] xxl:min-w-[400px] max-w-[300px]">
                                        <Button className="!bg-[#131620] !border-none"
                                            onClick={() => window.location.reload()}
                                        >
                                            <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />
                                            <span className='lg:!text-xs xl2:!text-sm  !uppercase !text-white !m-0'>
                                                Refresh
                                            </span>
                                        </Button>
                                    </div>
                                </div>

                                <SoccerStatement
                                    money={Number(money)}
                                    listBill={listBill}
                                    startDate={startDate}
                                    endDate={endDate}
                                    time={time}
                                    setStartDate={(e: any) => setStartDate(e)}
                                    setEndDate={(e: any) => setEndDate(e)}
                                    getCurrentMoney={getCurrentMoney}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* footer mobile */}
                <div className="xs:block xl:hidden">
                    <FooterMobile
                        setOpenDrawerBestSlip={() => setOpenDrawerBestSlip(true)}
                    />
                </div>
            </div>
        </Layout >
    )
}