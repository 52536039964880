/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../GameList/gameList.css'
import { InfoTournamentSoccer } from '../../../../../api/homePage/homePageApi';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { ChooseBetSoccer, lineBettingSoccer } from '../../../../../api/betting/bettingApi';
import useToast from '../../../../../hook/useToast';
import { checkNoData } from '../../../../../until';
import { LogoWithoutText, video } from '../../../../../components/imgExport';
import useDebounce from '../../../../../hook/useDebounce';
import SoccerTopLiveV3 from './SoccerTopLiveV3';
import io from 'socket.io-client';
import { API_URL_SOCCER } from '../../../../../env';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import useThrottle from '../../../../../hook/useThrottle';
import LazyLoad from 'react-lazyload';
import SoccerTopLiveV3New from './SoccerTopLiveV3New';
function arraysEqual<T>(array1: T[], array2: T[]): boolean {
    // Nếu độ dài của mảng không bằng nhau thì chắc chắn chúng không bằng nhau
    if (array1.length !== array2.length) {
        return false;
    }

    // So sánh từng phần tử của hai mảng
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    // Nếu tất cả các phần tử đều bằng nhau, trả về true
    return true;
}

interface GameSoccerProps {
    tournament: string
    item: InfoTournamentSoccer
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    // id: string
    // tournament: string
    // itemGame: InfoGameCR
    // listChooseBet: ChooseBet[]

    chooseBetSoccer?: (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string, homeScore?: number, awayScore?: number
    ) => void
    countTotalSoccer?: (
        count: number,
        tournament?: string
    ) => void
    checkUpdateOdds?: (id: number, point: number, prices: any[]) => void
    checkUpdateStrightBetsSlip?: (id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number) => void
    blockGameID?: (id: number | string) => void
}
const GameSoccerLive_V2: React.FC<GameSoccerProps> = ({
    tournament, item, listChooseBet, listAddStrightBetsSlipSoccer,
    chooseBetSoccer, countTotalSoccer, checkUpdateOdds, checkUpdateStrightBetsSlip,
    blockGameID

}) => {
    const pushToast = useToast();

    const [listGameSoccer, setListGameSoccer] = useState<homePage_V2Api.InfoGameSoccer[]>([])
    const [socket, setSocket] = useState<any>(null);
    const gGameSoccerByTournamentName = useDebounce(async () => {
        const ketQua = await homePage_V2Api.getGameSoccerLiveByTournamentName(item.tournament);
        if (ketQua?.success) {
            countTotalSoccer?.(ketQua.data.length, item.tournament)
            setListGameSoccer([]);
            const updateList = ketQua.data.map((item) => ({
                ...item,
                time: Number((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60) > 0 ?
                    ((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60).toFixed(0)
                    :
                    (60 - Number(Math.abs((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60)) % 60).toFixed(0)
            }))
            setListGameSoccer(updateList)
        } else {
            setListGameSoccer([]);
            pushToast(ketQua?.message, "warn");
        }
    }, 1000)

    useEffect(() => {
        gGameSoccerByTournamentName()
    }, [])

    useEffect(() => {
        const socketInstance = io(API_URL_SOCCER);
        setSocket(socketInstance);
        socketInstance.on('connect', () => {
            console.log('Connected to server live');
        });
        socketInstance.on('message-soccer', async (data) => {
            const updateList = data.filter((bet: any) => (bet.tournament.tournamentName === tournament)).map((item: any) => ({
                ...item,
                time: Number((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60) > 0 ?
                    ((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60).toFixed(0)
                    :
                    (60 - Number(Math.abs((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60)) % 60).toFixed(0)
                ,
                oddsOld: listGameSoccer.filter((betOld: any) => (betOld.id === item.id))[0]?.odds
            }))
            setListGameSoccer(updateList)

        });
        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
    }, []);

    const blockGame = useThrottle(() => {
        const listId = (listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer.length > 0) ? listAddStrightBetsSlipSoccer.map(ItemStraight => ItemStraight.idGame) : [];
        listGameSoccer && listGameSoccer.map((itemGame: homePage_V2Api.InfoGameSoccer) => {
            if (itemGame.homeTeam.teamName.includes("(ET)") && itemGame.awayTeam.teamName.includes("(ET)")) {
                if ((itemGame.time % 60 >= 15 && listId.includes(itemGame.id)) || (item.tournament.includes("e-Football") && itemGame.time % 60 >= 8 && listId.includes(itemGame.id))) {
                    blockGameID && blockGameID(itemGame.id)
                }
            }
            if ((itemGame.time % 60 >= 45 && listId.includes(itemGame.id)) || (item.tournament.includes("e-Football") && itemGame.time % 60 >= 8 && listId.includes(itemGame.id))) {
                blockGameID && blockGameID(itemGame.id)
            }

        })
    }, 1000)

    useEffect(() => {
        if (listChooseBet.length > 0 || listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer.length > 0) {
            blockGame()
        }
    }, [listGameSoccer])

    return (
        <>

            {
                listGameSoccer && listGameSoccer.map((item, i) =>
                    <LazyLoad height={200} key={i}>
                        <div key={i} className='bg-[#131620]'>
                            <div className='flex xs:gap-3 sm:gap-0 xs:flex-col sm:flex-row justify-between xs:items-start sm:items-center'>
                                <div className='flex items-center gap-4'>
                                    <div className='flex items-center gap-2'>
                                        <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                            <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                        </div>
                                        <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{item.homeTeam.teamName}</span>
                                    </div>
                                    <span className='text-[20px] text-[#D4B962]'>VS</span>
                                    <div className='flex items-center gap-2'>
                                        <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                            <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                        </div>
                                        <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{item.awayTeam.teamName}</span>
                                    </div>
                                </div>
                                <div className='h-6 flex items-center justify-between gap-2 rounded-xl border-[#FF4A59] border w-fit px-2 text-[16px]'>
                                    <img src={video} alt="" />
                                    <span className='fw-[500] text-sm text-[#FFFFFF] whitespace-nowrap'>
                                        {item.mainMarketEventResult.extraInfo.period === 5 ? 'HT' : item.mainMarketEventResult.extraInfo.period === 0 ? 'LIVE' : (item.mainMarketEventResult.extraInfo.period + 'H ' + (item.time % 60 === 59 ? "0" : item.time % 60) + "'")}
                                    </span>
                                    {item.mainMarketEventResult.extraInfo.injuryTime !== 0 ?
                                        <span className="fw-[500] text-sm text-[#FFFFFF] -ml-1 whitespace-nowrap">
                                            {" +" + item.mainMarketEventResult.extraInfo.injuryTime + "'"}
                                        </span>
                                        :
                                        ""
                                    }
                                    <div className='w-[18px] border border-[#D4B962]'></div>
                                    <span className='fw-[500] text-sm text-[#FFFFFF] pr-3'>
                                        {item.mainMarketEventResult.liveHomeScore + ' : ' + item.mainMarketEventResult.liveAwayScore}
                                    </span>
                                </div>
                            </div>
                            <div className='flex gap-1 pt-3 w-full'>
                                <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] w-[50%]'>
                                    <div className='w-full border-b border-[#282D39] py-2'>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1] '>Full time</span>
                                    </div>
                                    <div className='flex items-center justify-around py-2'>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>HDP</span>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>OU</span>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'> 1x2</span>
                                    </div>
                                </div>
                                <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] w-[50%]'>
                                    <div className='w-full border-b border-[#282D39] py-2'>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1] '>First half</span>
                                    </div>
                                    <div className='flex items-center justify-around py-2'>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>HDP</span>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'>OU</span>
                                        <span className='font-bold-GT-America text-sm text-[#E4E7F1]'> 1x2</span>
                                    </div>
                                </div>
                            </div>
                            {/* Cũ */}
                            {/* <div className='bg-white p-[16px]'>
                            <div className='soccerTop p-[10px] columns-3 flex justify-between items-center px-16 border-black-100 rounded-sm'>
                                <div className='flex flex-col items-center w-[180px]'>
                                    <img className='w-[44px] h-[44px]'
                                        src={LogoWithoutText}
                                        alt="NYKnicks" />
                                    <span className='font-bold-GT-America text-[16px]'>
                                        {item.homeTeam.teamName}
                                    </span>
                                </div>
                                <div className='flex flex-col justify-center w-[100px] text-center'>
                                    <span className="text-black">
                                        {item.mainMarketEventResult.liveHomeScore + ' : ' + item.mainMarketEventResult.liveAwayScore}
                                    </span>
                                    <span className="text-[#BB271A]">
                                        {item.mainMarketEventResult.extraInfo.period === 5 ? 'HT' : item.mainMarketEventResult.extraInfo.period === 0 ? 'LIVE' : (item.mainMarketEventResult.extraInfo.period + 'H ' + item.time % 60 + "'")}
                                    </span>
                                    <span className="text-[#989898]">
                                        {item.mainMarketEventResult.extraInfo.injuryTime !== 0 ? "+" + item.mainMarketEventResult.extraInfo.injuryTime + "'" : ""}
                                    </span>

                                    <span className='text-[#BB271A] font-bold-GT-America'>
                                    </span>
                                </div>
                                <div className='flex flex-col items-center w-[180px]'>
                                    <img className='w-[44px] h-[44px]' src={LogoWithoutText} alt="WASWizards" />
                                    <span className='font-bold-GT-America text-[16px]'>
                                        {item.awayTeam.teamName}
                                    </span>
                                </div>
                            </div>
                        </div> */}
                            <div className=" soccerGame !bg-[#131620] flex flex-col w-full justify-between">
                                <SoccerTopLiveV3New
                                    idGame={item.id}
                                    homeTeam={item.homeTeam.teamName}
                                    awayTeam={item.awayTeam.teamName}
                                    homeScore={item.mainMarketEventResult.liveHomeScore ? Number(item.mainMarketEventResult.liveHomeScore) : 0}
                                    awayScore={item.mainMarketEventResult.liveAwayScore ? Number(item.mainMarketEventResult.liveAwayScore) : 0}
                                    homeScoreTotalCorner={item.mainMarketEventResult?.subMarketEventResult?.totalCorners?.liveHomeScore ? Number(item.mainMarketEventResult?.subMarketEventResult?.totalCorners?.liveHomeScore) : 0}
                                    awayScoreTotalCorner={item.mainMarketEventResult?.subMarketEventResult?.totalCorners?.liveAwayScore ? Number(item.mainMarketEventResult?.subMarketEventResult?.totalCorners?.liveAwayScore) : 0}
                                    tournament={tournament}
                                    item={item}
                                    listChooseBet={listChooseBet}
                                    listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                    blockAllOdds={item.time ? (tournament.includes("e-Football") ? item.time % 60 >= 8 : (item.homeTeam.teamName.includes("(ET)") ? item.time % 60 >= 15 : item.time % 60 >= 45)) : false}
                                    chooseBetSoccer={chooseBetSoccer}
                                    checkUpdateOdds={checkUpdateOdds}
                                    checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                    hiddenFirstHalf={item.mainMarketEventResult.extraInfo.period && (Number(item.mainMarketEventResult.extraInfo.period) === 2 || item.mainMarketEventResult.extraInfo.period === 5) ? true : false}
                                />
                            </div >
                        </div>

                    </LazyLoad>
                )
            }
            {

                checkNoData(listGameSoccer, 'pb-4')

            }
            {/* <SoccerTime
            /> */}

        </>

    );
};

export default GameSoccerLive_V2;