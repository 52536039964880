import React from 'react';
import '../GameList/gameList.css'
import { bg_checked, checked, ElipseRed, LogoWithoutText } from '../../../../../components/imgExport';
import { Tooltip } from 'antd';
import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import { ChooseBet } from '../../../../../api/betting/bettingApi';
import { InfoBetMGM } from '../../../../../api/homePage/homePage_V2Api';
import { ICON_TEAM } from '../../../../../env';
import { covertDataGames, covertDataGamesOld, covertDataOptionMarkets, covertDataOptionMarketsOld, getChangeClass_V3, getChangeColorText, getChangeColor_V3, getCheckSelect_V3 } from '../../../../../until';
interface GameDetailProps {
    sport?: string
    tournament: string
    item: InfoBetMGM
    listChooseBet: ChooseBet[]
    competitionsLiveOld: { [key: string]: InfoBetMGM[] }
    chooseBet?: (
        tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total", dateEvent: string, live: boolean, id?: number | string, idGame?: number | string, optionId?: number | string, homeScore?: number, awayScore?: number, dateEventServer?: string, order?: number
    ) => void
    checkUpdateStrightBetsSlip: (teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total", color: string, homeScore?: number, awayScore?: number, id?: number | string) => void
    decimal: string
    inputFilter?: string
}
const GameLiveDeTail_V2: React.FC<GameDetailProps> = ({
    sport, tournament, item, listChooseBet, competitionsLiveOld, decimal, inputFilter,
    chooseBet, checkUpdateStrightBetsSlip

}) => {
    const { games, startDate, participants, optionMarkets } = item
    const { spread, totals, moneyLine } = covertDataGames(games)
    var spreadOption: any
    var totalsOption: any
    var moneyLineOption: any
    if (games.length === 0) {
        spreadOption = covertDataOptionMarkets(optionMarkets).spread
        totalsOption = covertDataOptionMarkets(optionMarkets).totals
        moneyLineOption = covertDataOptionMarkets(optionMarkets).moneyLine
        if (!spreadOption) {
            spreadOption = optionMarkets.filter((market) => market.name.value === "Run Line Spread")[0]
        }
    }
    var spreadRunLine: any
    if (!spread) {
        spreadRunLine = optionMarkets.filter((game) => game.name.value === "Run Line Spread")[0]
    }

    var xx: any
    var spreadOld: any
    var totalsOld: any
    var moneyLineOld: any
    if (competitionsLiveOld && competitionsLiveOld[tournament] && competitionsLiveOld[tournament].length > 0) {
        xx = competitionsLiveOld[tournament].filter((x) => x.id === item.id)[0]
        if (xx) {
            if (games.length === 0) {
                if (covertDataOptionMarketsOld(xx.optionMarkets)) {
                    spreadOld = covertDataOptionMarketsOld(xx.optionMarkets).spreadOld
                    if (!spreadOld) {
                        spreadOld = xx.optionMarkets.filter((market: any) => market.name.value === "Run Line Spread")[0]
                    }
                    totalsOld = covertDataOptionMarketsOld(xx.optionMarkets).totalsOld
                    moneyLineOld = covertDataOptionMarketsOld(xx.optionMarkets).moneyLineOld
                }
            } else {
                if (covertDataGamesOld(xx.games)) {
                    spreadOld = covertDataGamesOld(xx.games).spreadOld
                    if (!spreadOld) {
                        spreadOld = xx.games.filter((games: any) => games.name.value === "Run Line Spread")[0]
                    }
                    totalsOld = covertDataGamesOld(xx.games).totalsOld
                    moneyLineOld = covertDataGamesOld(xx.games).moneyLineOld
                }
            }

        }
        // { spreadOld, totalsOld, moneyLineOld } =  covertDataGamesOld(xx.games)
    }

    // const { spreadOld, totalsOld, moneyLineOld } = (xx) ? covertDataGamesOld(xx.games) : {}
    const handelBet = (teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total", id?: number | string, order?: number) => {
        const game = participants[1].name.value + " / " + participants[0].name.value
        chooseBet?.(tournament, game, teamChoose, scoreChoose, lineBetting, startDate, true, item.id, item.id, id,
            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
            startDate, order
        )
        //Thiếu truyền tỉ số hiện tại ở kèo MGM vào đây
    }

    return (
        <>
            <div className='xs:hidden xl:flex md:w-[160px] xl:w-[160px] xl2:w-[220px] xl3:w-[220px] items-center justify-between px-2 '>
                <div className='flex items-center gap-2'>
                    <img className="pr-1" src={ElipseRed} alt="ElipseRed" />
                    <span className="fw-[500] xs:text-xs md:text-sm text-[#FFFFFF]">{item.scoreboard?.period ? item.scoreboard?.period + (item.scoreboard?.indicator ? item.scoreboard?.indicator : "") : startDate}</span>
                </div>
            </div>

            <div className={` flex items-center w-full justify-between border-b border-b-[#1D212D] ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
            >
                {
                    games.length !== 0 && (
                        <div className={`!pl-0 mb-2 bg-[#131620] rounded-2xl flex xs:flex-col md:flex-row w-full justify-between ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
                        >
                            <Tooltip placement="right">
                                <div className="flex flex-col">

                                    <div className="gameTime bg-[#131620] flex xs:justify-between xl:justify-start items-center"
                                    // onClick={() => navigator("/detailPage")}
                                    >
                                        <div className='flex xs:flex-row xl:flex-col items-start gap-3'>
                                            <div className=' flex items-center h-[56px] justify-between xs:gap-2 md:gap-0 md:w-[160px] xl:w-[160px] xl2:w-[190px] xl3:w-[190px]'>
                                                <div className='flex items-center gap-2'>
                                                    <img
                                                        className='w-[30px] h-[30px]'
                                                        // src={LogoWithoutText}
                                                        src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[0].image?.logo) : (ICON_TEAM + `7/` + participants[0].image?.logo)}
                                                        alt="team1"
                                                        onError={(e) => {
                                                            e.currentTarget.src = LogoWithoutText;
                                                        }} />
                                                    <span className='xs:text-[10px] xl:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[0].name.short}</span>
                                                </div>

                                                <b className='xs:text-[10px] xl:text-sm pr-1 text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
                                            </div>
                                            {/* <span className='text-[#D4B962] text-base'>VS</span> */}
                                            <div className=' flex items-center h-[56px] justify-between xs:gap-2 md:gap-0 md:w-[160px] xl:w-[160px] xl2:w-[190px] xl3:w-[190px]'>
                                                <div className='flex items-center gap-2'>
                                                    <img
                                                        className=' w-[30px] h-[30px]'
                                                        // src={LogoWithoutText}
                                                        src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[1].image?.logo) : (ICON_TEAM + `7/` + participants[1].image?.logo)}
                                                        alt="team1"
                                                        onError={(e) => {
                                                            e.currentTarget.src = LogoWithoutText;
                                                        }} />
                                                    <span className='xs:text-[10px] xl:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[1].name.short}</span>
                                                </div>

                                                <b className='xs:text-[10px] xl:text-sm pr-1 text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
                                            </div>
                                        </div>
                                        <div className='xs:flex xs:w-fit md:hidden md:w-[160px] xl:w-[160px] xl2:w-[220px] xl3:w-[220px] rounded-xl border border-[#2B313E] bg-[#202531]  items-center justify-between px-2 '>
                                            <div className='flex items-center gap-2'>
                                                <img className="pr-1" src={ElipseRed} alt="ElipseRed" />
                                                <span className="fw-[500] xs:text-xs md:text-sm text-[#FFFFFF]">{item.scoreboard?.period ? item.scoreboard?.period + (item.scoreboard?.indicator ? item.scoreboard?.indicator : "") : startDate}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='xs:flex md:hidden gap-3  w-full justify-end'>
                                        <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] xs:w-1/2  xl:w-[250px] xl2:w-[250px] xl3:w-[400px]'>
                                            <div className='w-full border-b border-[#282D39] py-2'>
                                                <span className='font-bold-GT-America text-sm text-[#E4E7F1] uppercase'>Full time</span>
                                            </div>
                                            <div className='flex items-center justify-around py-2'>
                                                <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>SPREAD</span>
                                                <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>TOTAL</span>
                                                <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>MONEY LINE</span>
                                            </div>
                                        </div>

                                        <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] xs:w-1/2  xl:w-[250px] xl2:w-[250px] xl3:w-[400px]'>
                                            <div className='w-full border-b border-[#282D39] py-2'>
                                                <span className='font-bold-GT-America text-sm text-[#E4E7F1] uppercase'>First half</span>
                                            </div>
                                            <div className='flex items-center justify-around py-2'>
                                                <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>SPREAD</span>
                                                <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>TOTAL</span>
                                                <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>MONEY LINE</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Tooltip>
                            {item.scoreboard?.period === '9th Inning' || item.scoreboard?.period === '10th Inning' || item.scoreboard?.period === '11th Inning' || item.scoreboard?.period === '12th Inning' || item.scoreboard?.period === '13th Inning' || item.scoreboard?.period === '14th Inning' || item.scoreboard?.period === '15th Inning' ?
                                <div className="gameRatio xs:pr-0 md:pr-2 flex flex-col gap-3 justify-center">
                                    <div className="homeRaito flex gap-2">
                                        <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="homeRaito flex  gap-2">

                                        <div className='flex  xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2'>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                :
                                <div className="gameRatio xs:pr-0 md:pr-2 flex flex-col gap-3 justify-center">
                                    <div className="homeRaito flex gap-2">

                                        {(sport === "NFL" || sport === "NBA" || sport !== "") && (item.scoreboard?.period === "Halftime" || item.scoreboard?.period !== "Halftime") ? (
                                            <>
                                                <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                                    {spread?.visibility === "Visible" ? (
                                                        <div
                                                            className={
                                                                `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`
                                                            }
                                                        >
                                                            {
                                                                spread?.results?.[0]?.visibility === "Visible" && spread?.results[0]?.attr ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[0]?.odds : spreadOld?.results[0]?.americanOdds) : 0,
                                                                        participants[0].name.value, "Spread", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, spread?.results[0]?.attr + " " + (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? spreadOld?.results[0]?.odds : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            spread?.results[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, spread?.results[0]?.attr + " " + (decimal !== 'American' ? spread?.results[0]?.odds : (Number(spread?.results[0]?.americanOdds) > 0 ? '+' + spread?.results[0]?.americanOdds : spread?.results[0]?.americanOdds)), "Spread", spread?.results[0]?.id, 0)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spread?.results[0]?.attr}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="!font-normal">{`${(decimal !== 'American' ? spread?.results[0]?.odds.toFixed(2) : spread?.results[0]?.americanOdds)}`}</b></p>

                                                                    </div> :
                                                                    spreadRunLine?.results[0]?.attr ?
                                                                        <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                            (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[0]?.odds : spreadOld?.results[0]?.americanOdds) : 0,
                                                                            participants[0].name.value, "Spread", startDate, listChooseBet,
                                                                            () => checkUpdateStrightBetsSlip(participants[0].name.value, spread?.results[0]?.attr + " " + (decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? spreadOld?.results[0]?.odds : 0),
                                                                                item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                                item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                                spread?.results[0]?.id
                                                                            )
                                                                        )}`}
                                                                            onClick={() => handelBet?.(participants[0].name.value, spreadRunLine?.results[0]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.results[0]?.price?.odds : (Number(spreadRunLine?.results[0]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.results[0]?.price?.americanOdds}` : spreadRunLine?.results[0]?.price?.americanOdds)), "Spread", spreadRunLine?.results[0]?.id, 0)}
                                                                        >
                                                                            <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spreadRunLine?.results[0]?.attr}`}</p>
                                                                            <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="!font-normal">{`${(decimal !== 'American' ? spreadRunLine?.results[0]?.price?.odds.toFixed(2) : spreadRunLine?.results[0]?.price?.americanOdds)}`}</b></p>
                                                                        </div> :
                                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread?.results[0]?.odds : spread?.results[0]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[0]?.odds : spreadOld?.results[0]?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {totals?.visibility === "Visible" ? (
                                                        <div
                                                            className={
                                                                ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962] `}
                                                        >
                                                            {
                                                                totals?.results?.[0]?.visibility === "Visible" && totals?.results[0]?.name.value ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[0]?.odds : totalsOld?.results[0]?.americanOdds) : 0,
                                                                        participants[0].name.value, "Total", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, totals?.results[0]?.name.value + " " + (decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), "Total", getChangeColorText((decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[0]?.odds : totalsOld?.results[0]?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            totals?.results[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, totals?.results[0]?.name.value + " " + (decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), "Total", totals?.results[0]?.id, 0)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totals?.results[0]?.name.value}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm !font-normal'><b className="!font-normal">{`${(decimal !== 'American' ? totals?.results[0]?.odds.toFixed(2) : (Number(totals?.results[0]?.americanOdds) > 0 ? `+${totals?.results[0]?.americanOdds}` : totals?.results[0]?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals?.results[0]?.odds : totals?.results[0]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[0]?.odds : totalsOld?.results[0]?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {moneyLine?.visibility === "Visible" ? (
                                                        <div
                                                            className={
                                                                ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]`}
                                                        >
                                                            {
                                                                moneyLine?.results[0]?.visibility === "Visible" && moneyLine?.results[0]?.odds ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? moneyLine?.results[0]?.odds : moneyLine?.results[0]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[0]?.odds : moneyLineOld?.results[0]?.americanOdds) : 0,
                                                                        participants[0].name.value, "Money", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? moneyLine?.results[0].odds?.toString() : moneyLine?.results[0].americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLine?.results[0]?.odds : moneyLine?.results[0]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[0]?.odds : moneyLineOld?.results[0]?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            moneyLine?.results[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLine?.results[0].odds?.toString() : moneyLine?.results[0].americanOdds?.toString()), "Money", moneyLine?.results[0]?.id, 0)}
                                                                    >
                                                                        <b className='text-[#D4B962] xs:text-xs md:text-sm !font-normal'>{(decimal !== 'American' ? moneyLine?.results[0]?.odds.toFixed(2) : (Number(moneyLine?.results[0]?.americanOdds) > 0 ? `+${moneyLine?.results[0]?.americanOdds}` : moneyLine?.results[0]?.americanOdds))}</b>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine?.results[0]?.odds : moneyLine?.results[0]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[0]?.odds : moneyLineOld?.results[0]?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                ` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full  pl-2 flex items-center flex-col  h-[40px] rounded-md `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>

                                                <div
                                                    className={
                                                        `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                            </>

                                        )}

                                        {/* Nếu mở live thì mở ở đây */}


                                    </div>

                                    <div className="awayRaito flex gap-2">

                                        {(sport === "NFL" || sport === "NBA" || sport !== "") && (item.scoreboard?.period === "Halftime" || item.scoreboard?.period !== "Halftime") ? (
                                            <>
                                                {spread?.visibility === "Visible" ? (
                                                    <div
                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                    >
                                                        {
                                                            spread?.results?.[1]?.visibility === "Visible" && spread?.results[1]?.attr ?
                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                    (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0,
                                                                    participants[1].name.value, "Spread", startDate, listChooseBet,
                                                                    () => checkUpdateStrightBetsSlip(participants[1].name.value, spread?.results[1]?.attr + " " + (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0),
                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                        spread?.results[1]?.id
                                                                    )
                                                                )}`}
                                                                    onClick={() => handelBet?.(participants[1].name.value, spread?.results[1]?.attr + " " + (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), "Spread", spread?.results[1]?.id, 1)}
                                                                >
                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spread?.results[1]?.attr}`}</p>
                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="!font-normal">{`${(decimal !== 'American' ? spread?.results[1]?.odds.toFixed(2) : (Number(spread?.results[1]?.americanOdds) > 0 ? '+' + spread?.results[1]?.americanOdds : spread?.results[1]?.americanOdds))}`}</b></p>
                                                                </div> :
                                                                spreadRunLine?.results[1]?.attr ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0,
                                                                        participants[1].name.value, "Spread", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, spread?.results[1]?.attr + " " + (decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), "Spread", getChangeColorText((decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            spread?.results[1]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, spreadRunLine?.results[1]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.results[1]?.price?.odds : spreadRunLine?.results[1]?.price?.americanOdds), "Spread", spreadRunLine?.results[1]?.id, 1)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spreadRunLine?.results[1]?.attr}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm '><b className="!font-normal">{`${(decimal !== 'American' ? spreadRunLine?.results[1]?.price?.odds.toFixed(2) : (Number(spreadRunLine?.results[1]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.results[1]?.price?.americanOdds}` : spreadRunLine?.results[1]?.price?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spread?.results[1]?.odds : spread?.results[1]?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.results[1]?.odds : spreadOld?.results[1]?.americanOdds) : 0)}`} />
                                                        {getCheckSelect_V3(participants[1].name.value, "Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}


                                                {totals?.visibility === "Visible" ? (
                                                    <div
                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                    >
                                                        {
                                                            totals?.results?.[1]?.visibility === "Visible" && totals?.results[1]?.name.value ?
                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                    (decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[1]?.odds : totalsOld?.results[1]?.americanOdds) : 0,
                                                                    participants[1].name.value, "Total", startDate, listChooseBet,
                                                                    () => checkUpdateStrightBetsSlip(participants[1].name.value, totals?.results[1]?.attr + " " + (decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), "Total", getChangeColorText((decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[1]?.odds : totalsOld?.results[1]?.americanOdds) : 0),
                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                        totals?.results[1]?.id
                                                                    )
                                                                )}`}
                                                                    onClick={() => handelBet?.(participants[1].name.value, totals?.results[1]?.name.value + " " + (decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), "Total", totals?.results[1]?.id, 1)}
                                                                >
                                                                    <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totals?.results[1]?.name.value}`}</p>
                                                                    <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="!font-normal">{`${(decimal !== 'American' ? totals?.results[1]?.odds.toFixed(2) : (Number(totals?.results[1]?.americanOdds) > 0 ? `+${totals?.results[1]?.americanOdds}` : totals?.results[1]?.americanOdds))}`}</b></p>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totals?.results[1]?.odds : totals?.results[1]?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.results[1]?.odds : totalsOld?.results[1]?.americanOdds) : 0)}`} />
                                                        {getCheckSelect_V3(participants[1].name.value, "Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>

                                                    </div>
                                                )}

                                                {moneyLine?.visibility === "Visible" ? (
                                                    <div
                                                        className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                    >
                                                        {
                                                            moneyLine?.results?.[1]?.visibility === "Visible" && moneyLine?.results[1]?.odds ?
                                                                <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                    (decimal !== 'American' ? moneyLine?.results[1]?.odds : moneyLine?.results[1]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[1]?.odds : moneyLineOld?.results[1]?.americanOdds) : 0,
                                                                    participants[1].name.value, "Money", startDate, listChooseBet,
                                                                    () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? moneyLine?.results[1]?.odds?.toString() : moneyLine?.results[1]?.americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLine?.results[1]?.odds : moneyLine?.results[1]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[1]?.odds : moneyLineOld?.results[1]?.americanOdds) : 0),
                                                                        item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                        item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                        moneyLine?.results[1]?.id
                                                                    )
                                                                )}`}
                                                                    onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLine?.results[1]?.odds?.toString() : moneyLine?.results[1]?.americanOdds?.toString()), "Money", moneyLine?.results[1]?.id, 1)}
                                                                >
                                                                    <b className='text-[#D4B962] xs:text-xs md:text-sm !font-normal'>{(decimal !== 'American' ? moneyLine?.results[1]?.odds.toFixed(2) : (Number(moneyLine?.results[1]?.americanOdds) > 0 ? `+${moneyLine?.results[1]?.americanOdds}` : moneyLine?.results[1]?.americanOdds))}</b>
                                                                </div> :
                                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        }
                                                        <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLine?.results[1]?.odds : moneyLine?.results[1]?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.results[1]?.odds : moneyLineOld?.results[1]?.americanOdds) : 0)}`} />
                                                        {getCheckSelect_V3(participants[1].name.value, "Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                    >
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                )}
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div
                                                    className={
                                                        `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}
                                                >
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                                <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                </div>
                                            </>
                                        )}

                                        {/* Nếu mở live thì mở ở đây */}

                                    </div>


                                </div>
                            }

                        </div >
                    )}

                {
                    games.length === 0 && (
                        <div className={`!pl-0 mb-2 bg-[#131620] rounded-2xl flex w-full xs:flex-col xl:flex-row justify-between ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
                        >
                            <Tooltip placement="right">
                                <div className="gameTime bg-[#131620] flex xs:justify-between xl:justify-start items-center"
                                // onClick={() => navigator("/detailPage")}
                                >


                                    <div className='flex xs:flex-row xl:flex-col items-start gap-3'>
                                        <div className='flex items-center h-[56px] justify-between xs:gap-2 md:gap-0 md:w-[160px] xl:w-[160px] xl2:w-[190px] xl3:w-[190px]'>
                                            <div className='flex items-center gap-2'>
                                                <img
                                                    className='w-[30px] h-[30px]'
                                                    // src={LogoWithoutText}
                                                    src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[0].image?.logo) : (ICON_TEAM + `7/` + participants[0].image?.logo)}
                                                    alt="team1"
                                                    onError={(e) => {
                                                        e.currentTarget.src = LogoWithoutText;
                                                    }} />
                                                <span className='xs:text-[10px] xl:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[0].name.short}</span>
                                            </div>

                                            <b className='pxs:text-[10px] xl:text-sm r-1 text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
                                        </div>
                                        {/* <span className='text-[#D4B962] text-base'>VS</span> */}
                                        <div className='flex items-center h-[56px] justify-between xs:gap-2 md:gap-0 md:w-[160px] xl:w-[160px] xl2:w-[190px] xl3:w-[190px]'>
                                            <div className='flex items-center gap-2'>
                                                <img
                                                    className=' w-[30px] h-[30px]'
                                                    // src={LogoWithoutText}
                                                    src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[1].image?.logo) : (ICON_TEAM + `7/` + participants[1].image?.logo)}
                                                    alt="team1"
                                                    onError={(e) => {
                                                        e.currentTarget.src = LogoWithoutText;
                                                    }} />
                                                <span className='xs:text-[10px] xl:text-sm text-[#E4E7F1] fw-[#E4E7F1]'>{participants[1].name.short}</span>
                                            </div>

                                            <b className='xs:text-[10px] xl:text-sm pr-1 text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
                                        </div>
                                    </div>
                                    <div className='xs:flex xs:w-fit xl:hidden  xl:w-[160px] xl2:w-[220px] xl3:w-[220px] rounded-xl border border-[#2B313E] bg-[#202531]  items-center justify-between px-2 '>
                                        <div className='flex items-center gap-2'>
                                            <img className="pr-1" src={ElipseRed} alt="ElipseRed" />
                                            <span className="fw-[500] xs:text-xs md:text-sm text-[#FFFFFF]">{item.scoreboard?.period ? item.scoreboard?.period + (item.scoreboard?.indicator ? item.scoreboard?.indicator : "") : startDate}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='xs:flex md:hidden gap-3  w-full justify-end'>
                                    <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] xs:w-1/2 lg:w-[250px] xl3:w-[400px]'>
                                        <div className='w-full border-b border-[#282D39] py-2'>
                                            <span className='font-bold-GT-America text-sm  text-[#E4E7F1] uppercase'>Full time</span>
                                        </div>
                                        <div className='grid grid-cols-3 gap-2 py-2'>
                                            <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>SPREAD</span>
                                            <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>TOTAL</span>
                                            <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>MONEY LINE</span>
                                        </div>
                                    </div>

                                    <div className='bg-header flex flex-col rounded-tl-[6px] rounded-tr-[6px] xs:w-1/2 lg:w-[250px]  xl3:w-[400px]'>
                                        <div className='w-full border-b border-[#282D39] py-2'>
                                            <span className='font-bold-GT-America text-sm  text-[#E4E7F1] uppercase'>First half</span>
                                        </div>
                                        <div className='grid grid-cols-3 gap-2 py-2'>
                                            <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>SPREAD</span>
                                            <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>TOTAL</span>
                                            <span className='font-bold-GT-America xs:text-[10px] md:text-sm text-[#E4E7F1]'>MONEY LINE</span>
                                        </div>
                                    </div>
                                </div>
                            </Tooltip>
                            {item.scoreboard?.period === '9th Inning' || item.scoreboard?.period === '10th Inning' || item.scoreboard?.period === '11th Inning' || item.scoreboard?.period === '12th Inning' || item.scoreboard?.period === '13th Inning' || item.scoreboard?.period === '14th Inning' || item.scoreboard?.period === '15th Inning' ?
                                <div className="gameRatio xs:pr-0 md:pr-2 flex flex-col gap-3 justify-center">
                                    <div className="homeRaito flex gap-2">

                                        <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="homeRaito flex  gap-2">

                                        <div className='flex  xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2'>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                            <div className={`my-2 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col h-[56px]  `}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                :
                                <div className="gameRatio xs:pr-0 md:pr-2 flex flex-col gap-3 justify-center">
                                    <div className="homeRaito flex gap-2 ">

                                        {(sport === "NFL" || sport === "NBA" || sport !== "") && (item.scoreboard?.period === "Halftime" || item.scoreboard?.period !== "Halftime") ? (
                                            <>
                                                <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                                    {spreadOption?.status === 'Visible' ? (
                                                        <div
                                                            className={
                                                                `bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:border-[#D4B962]
                            `
                                                            }
                                                        >
                                                            {
                                                                spreadOption?.options[0]?.status === 'Visible' && spreadOption?.options[0]?.attr ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[0]?.price?.odds : spreadOld?.options[0]?.price?.americanOdds) : 0,
                                                                        participants[0].name.value, "Spread", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, spreadOption?.options[0]?.attr + " " + (decimal !== 'American' ? spreadOption?.options[0]?.price?.odds?.toString() : spreadOption?.options[0]?.price?.americanOdds?.toString()), "Spread", getChangeColorText((decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[0]?.price?.odds : spreadOld?.options[0]?.price?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            spreadOption?.options[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, spreadOption?.options[0]?.attr + " " + (decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), "Spread", spreadOption?.options[0]?.id, 0)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spreadOption?.options[0]?.attr}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="!font-normal">{`${(decimal !== 'American' ? spreadOption?.options[0]?.price?.odds.toFixed(2) : (Number(spreadOption?.options[0]?.price?.americanOdds) > 0 ? `+${spreadOption?.options[0]?.price?.americanOdds}` : spreadOption?.options[0]?.price?.americanOdds))}`}</b></p>

                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spreadOption?.options[0]?.price?.odds : spreadOption?.options[0]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[0]?.price?.odds : spreadOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {totalsOption?.status === 'Visible' ? (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                totalsOption?.options[0]?.status === 'Visible' && totalsOption?.options[0]?.name.value ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3((decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[0]?.price?.odds : totalsOld?.options[0]?.price?.americanOdds) : 0,
                                                                        participants[0].name.value, "Total", startDate, listChooseBet,
                                                                        // totals?.results[0]?.name.value
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, totalsOption?.options[0]?.name.value + " " + (decimal !== 'American' ? totalsOption?.options[0]?.price?.odds?.toString() : totalsOption?.options[0]?.price?.americanOdds?.toString()), "Total", getChangeColorText((decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[0]?.price?.odds : totalsOld?.options[0]?.price?.americanOdds) : 0),
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255],
                                                                            totalsOption?.options[0]?.id
                                                                        )
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, totalsOption?.options[0]?.name.value + " " + (decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), "Total", totalsOption?.options[0]?.id, 0)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totalsOption?.options[0]?.name.value}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="!font-normal">{`${(decimal !== 'American' ? totalsOption?.options[0]?.price?.odds.toFixed(2) : (Number(totalsOption?.options[0]?.price?.americanOdds) > 0 ? `+${totalsOption?.options[0]?.price?.americanOdds}` : totalsOption?.options[0]?.price?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totalsOption?.options[0]?.price?.odds : totalsOption?.options[0]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[0]?.price?.odds : totalsOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}

                                                    {moneyLineOption?.status === 'Visible' ? (
                                                        <div
                                                            className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                moneyLineOption?.options[0]?.status === 'Visible' && moneyLineOption?.options[0]?.price?.odds ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds : moneyLineOption?.options[0]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[0]?.price?.odds : moneyLineOld?.options[0]?.price?.americanOdds) : 0,
                                                                        participants[0].name.value, "Money", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[0].name.value, (decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds?.toString() : moneyLineOption?.options[0]?.price?.americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds : moneyLineOption?.options[0]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[0]?.price?.odds : moneyLineOld?.options[0]?.price?.americanOdds,
                                                                            moneyLineOld?.options[0]?.id) : 0)
                                                                            ,
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds?.toString() : moneyLineOption?.options[0]?.price?.americanOdds?.toString()), "Money", moneyLineOption?.options[0]?.id, 0)}
                                                                    >
                                                                        <b className='text-[#D4B962] xs:text-xs md:text-sm !font-normal'>{(decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds?.toFixed(2) : (Number(moneyLineOption?.options[0]?.price?.americanOdds) > 0 ? `+${moneyLineOption?.options[0]?.price?.americanOdds}` : moneyLineOption?.options[0]?.price?.americanOdds))}</b>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLineOption?.options[0]?.price?.odds : moneyLineOption?.options[0]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[0]?.price?.odds : moneyLineOld?.options[0]?.price?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[0].name.value, "Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>

                                            </>
                                        ) : (
                                            <>
                                                <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {/* Nếu mở live thì mở ở đây */}
                                    </div>

                                    <div className="awayRaito flex gap-2">

                                        {(sport === "NFL" || sport === "NBA" || sport !== "") && (item.scoreboard?.period === "Halftime" || item.scoreboard?.period !== "Halftime") ? (
                                            <>
                                                <div className="homeRaito grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]">
                                                    {spreadOption?.status === 'Visible' ? (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                spreadOption?.options[1]?.status === 'Visible' && spreadOption?.options[1]?.attr ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[1]?.price?.odds : spreadOld?.options[1]?.price?.americanOdds) : 0,
                                                                        participants[1].name.value, "Spread", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? spreadOption?.options[1]?.attr + " " + spreadOption?.options[1]?.price?.odds?.toString() : spreadOption?.options[1]?.attr + " " + spreadOption?.options[1]?.price?.americanOdds?.toString()), "Spread", getChangeColorText((decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[1]?.price?.odds : spreadOld?.options[1]?.price?.americanOdds,
                                                                            spreadOld?.options[1]?.id) : 0)
                                                                            ,
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, spreadOption?.options[1]?.attr + " " + (decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), "Spread", spreadOption?.options[1]?.id, 1)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${spreadOption?.options[1]?.attr}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="!font-normal">{`${(decimal !== 'American' ? spreadOption?.options[1]?.price?.odds.toFixed(2) : (Number(spreadOption?.options[1]?.price?.americanOdds) > 0 ? `+${spreadOption?.options[1]?.price?.americanOdds}` : spreadOption?.options[1]?.price?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? spreadOption?.options[1]?.price?.odds : spreadOption?.options[1]?.price?.americanOdds), spreadOld ? (decimal !== 'American' ? spreadOld?.options[1]?.price?.odds : spreadOld?.options[1]?.price?.americanOdds) : 0)}`} />
                                                            {getCheckSelect_V3(participants[1].name.value, "Spread", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {totalsOption?.status === 'Visible' ? (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                totalsOption?.options[1]?.status === 'Visible' && totalsOption?.options[1]?.name.value ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[1]?.price?.odds : totalsOld?.options[1]?.price?.americanOdds) : 0,
                                                                        participants[1].name.value, "Total", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, totalsOption?.options[1]?.name.value + " " + totalsOption?.options[1]?.price?.odds?.toString(), "Total", getChangeColorText((decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[1]?.price?.odds : totalsOld?.options[1]?.price?.americanOdds,
                                                                            totalsOld?.options[1]?.id) : 0)
                                                                            ,
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, totalsOption?.options[1]?.name.value + " " + (decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), "Total", totalsOption?.options[1]?.id, 1)}
                                                                    >
                                                                        <p className='text-[#E4E7F1] xs:text-[10px] md:text-xs'>{`${totalsOption?.options[1]?.name.value}`}</p>
                                                                        <p className='text-[#D4B962] xs:text-xs md:text-sm'><b className="!font-normal">{`${(decimal !== 'American' ? totalsOption?.options[1]?.price?.odds.toFixed(2) : (Number(totalsOption?.options[1]?.price?.americanOdds) > 0 ? `+${totalsOption?.options[1]?.price?.americanOdds}` : totalsOption?.options[1]?.price?.americanOdds))}`}</b></p>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? totalsOption?.options[1]?.price?.odds : totalsOption?.options[1]?.price?.americanOdds), totalsOld ? (decimal !== 'American' ? totalsOld?.options[1]?.price?.odds : totalsOld?.options[1]?.price?.americanOdds) : 0)}`} />

                                                            {getCheckSelect_V3(participants[1].name.value, "Total", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}


                                                    {moneyLineOption?.status === 'Visible' ? (
                                                        <div
                                                            className={`bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 relative hover:!border-[#D4B962] overflow-hidden`}
                                                        >
                                                            {
                                                                moneyLineOption?.options[1]?.status === 'Visible' && moneyLineOption?.options[1]?.price?.odds ?
                                                                    <div className={`w-full h-full flex flex-col justify-center items-center bg-[#20273D] cursor-pointer hover:!border-[#D4B962] rounded-md ${getChangeColor_V3(
                                                                        (decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds : moneyLineOption?.options[1]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[1]?.price?.odds : moneyLineOld?.options[1]?.price?.americanOdds) : 0,
                                                                        participants[1].name.value, "Money", startDate, listChooseBet,
                                                                        () => checkUpdateStrightBetsSlip(participants[1].name.value, (decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds?.toString() : moneyLineOption?.options[1]?.price?.americanOdds?.toString()), "Money", getChangeColorText((decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds : moneyLineOption?.options[1]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[1]?.price?.odds : moneyLineOld?.options[1]?.price?.americanOdds,
                                                                            moneyLineOld?.options[1]?.id) : 0)
                                                                            ,
                                                                            item.scoreboard?.totalPoints?.player1?.[255] ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255],
                                                                            item.scoreboard?.totalPoints?.player2?.[255] ? item.scoreboard?.totalPoints?.player2?.[255] : item.scoreboard?.scoreDetailed?.player2?.[255])
                                                                    )}`}
                                                                        onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds?.toString() : moneyLineOption?.options[1]?.price?.americanOdds?.toString()), "Money", moneyLineOption?.options[1]?.id, 1)}
                                                                    ><b className='text-[#D4B962] xs:text-xs md:text-sm !font-normal'>{(decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds.toFixed(2) : (Number(moneyLineOption?.options[1]?.price?.americanOdds) > 0 ? `+${moneyLineOption?.options[1]?.price?.americanOdds}` : moneyLineOption?.options[1]?.price?.americanOdds))}</b>
                                                                    </div> :
                                                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                            }
                                                            <CaretRightOutlined className={`${getChangeClass_V3((decimal !== 'American' ? moneyLineOption?.options[1]?.price?.odds : moneyLineOption?.options[1]?.price?.americanOdds), moneyLineOld ? (decimal !== 'American' ? moneyLineOld?.options[1]?.price?.odds : moneyLineOld?.options[1]?.price?.americanOdds) : 0)}`} />

                                                            {getCheckSelect_V3(participants[1].name.value, "Money", listChooseBet).includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}
                                                        >
                                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                        </div>
                                                    )}
                                                </div>


                                                <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-3 xs:w-1/2 lg:w-[250px] xl3:w-[400px] gap-2 h-[40px]'>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10  `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                    <div className={` bg-[#20273D] border border-[#202531] !p-0 justify-center w-full flex items-center flex-col rounded-md h-10 `}>
                                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {/* Nếu mở live thì mở ở đây */}


                                    </div>
                                </div>
                            }

                        </div >
                    )}
            </div>


        </>
    )
}

export default GameLiveDeTail_V2;