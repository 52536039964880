import React, { useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import { ChooseBetSoccer } from '../../../../../api/betting/bettingApi';
import { checkSelectSoccerNew_V2, getChangeClass_V3, getChangeColorSoccer_V3 } from '../../../../../until';
import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import { InfoGameDetailSoccer, Odd } from '../../../../../api/homePage/homePage_V2Api';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import { bg_checked, checked } from '../../../../../components/imgExport';

function isInt(value: any) {
    return !isNaN(value) && (function (x) { return (x | 0) === x; })(parseFloat(value))
}
interface SoccerTopHDPFProps {
    live?: boolean
    homeTeam: string
    awayTeam: string
    homeScore?: number
    awayScore?: number
    item: InfoGameDetailSoccer[]
    oddsOld?: Odd[]
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    chooseBetTop?: (
        order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
    ) => void
    checkUpdateStrightBetsSlip?: (
        id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number
    ) => void
    updateOdds?: any[]
    dateFilterSoccer?: string
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
}
const SoccerTopOUFNew: React.FC<SoccerTopHDPFProps> = ({
    live, homeTeam, awayTeam, item, listChooseBet, listAddStrightBetsSlipSoccer, oddsOld, homeScore, awayScore, dateFilterSoccer, listDateFilterSoccer,
    chooseBetTop, checkUpdateStrightBetsSlip,
    updateOdds
}) => {

    const odd = useRef<any[]>([item])
    const oddOld = useRef<any[]>([oddsOld])
    const updateArrayOdds = async () => {
        item.forEach(element => {
            updateOdds?.forEach(elementUpdateOdds => {
                if (element.id === elementUpdateOdds.id) {
                    let a = odd.current
                    setTimeout(() => {
                        const abc: any[] = odd.current.map(item => {
                            return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
                        })
                        odd.current = abc
                        oddOld.current = a
                    }, 500);
                }
            });
        });
        setItemState(odd.current);
        setTimeout(() => {
            oddOld.current = odd.current
        }, 2000);
    }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (updateOdds) {
    //             // setItemStateOld(oddOld.current)
    //             setItemState(odd.current);
    //         } else {
    //             // setItemStateOld(odd.current)
    //             setItemState(odd.current);
    //         }
    //     }, 3000);

    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        updateArrayOdds()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOdds])

    const [itemState, setItemState] = useState<any[]>([item]);
    useEffect(() => {
        item.forEach(itemDetail => {
            itemDetail.prices.sort((a, b) => {
                if (a.option === "h") return -1; // Đưa option = "h" lên đầu
                if (b.option === "h") return 1; // Đưa option = "h" lên đầu
                return 0; // Giữ nguyên thứ tự nếu option không phải "h"
            });
        });
        if (oddsOld) {
            oddsOld.forEach(itemDetail => {
                itemDetail.prices.sort((a, b) => {
                    if (a.option === "h") return -1; // Đưa option = "h" lên đầu
                    if (b.option === "h") return 1; // Đưa option = "h" lên đầu
                    return 0; // Giữ nguyên thứ tự nếu option không phải "h"
                });
            });
        }
        setItemState(item)
        odd.current = item
        oddOld.current = oddsOld ? oddsOld : item
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    // useEffect(() => {
    //     setTimeout(() => {
    //         oddOld.current = odd.current
    //     }, 3000);
    // }, [itemState])

    return (
        <>
            <div className={`w-1/3 flex flex-col justify-start a1x2`}>
                <div className='flex justify-between w-full h-[110px] items-center '>
                    <div className='w-full flex flex-col hdp gap-1'>
                        <div className={`justify-center w-full flex items-center `}>
                            {/* // Fulltime HDP */}
                            {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 0
                                ?
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[0]?.price, oddOld.current?.[0]?.prices?.[0]?.price,
                                    () => (listChooseBet && odd.current?.[0]?.prices?.[0]?.price !== oddOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemState?.[0]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, itemState[0].point + "-Over " + itemState[0].prices[0].price, itemState?.[0]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer
                                )} ${checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam, "Fulltime OU")}`}
                                    onClick={() => chooseBetTop?.(
                                        0, itemState[0].id, homeTeam, itemState[0].point + "-Over " + itemState[0].prices[0].price, itemState?.[0]?.prices?.[0]?.option
                                    )}
                                >
                                    <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                        {(isInt((Math.abs(itemState[0].point)).toFixed(2)) ? (Math.abs(itemState[0].point)).toFixed(1) : (Math.abs(itemState[0].point)).toFixed(2))} - O
                                    </span>
                                    <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                        <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemState[0].prices[0].price).toFixed(2)}</b></p>
                                        <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[0]?.price, oddOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                    </div>
                                    {checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam, "Fulltime OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                </div>
                                :
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                    <div className={`relative py-1 w-full flex justify-center`}>
                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={`justify-center w-full flex items-center `}>
                            {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 1
                                ?
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[1]?.price, oddOld.current?.[0]?.prices?.[1]?.price,
                                    () => (listChooseBet && odd.current?.[0]?.prices?.[1]?.price !== oddOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemState?.[0]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, itemState[0].point + "-Under " + itemState[0].prices[1].price, itemState?.[0]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer
                                )} ${checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, awayTeam, "Fulltime OU")}`}
                                    onClick={() => chooseBetTop?.(
                                        0, itemState[0].id, awayTeam, itemState[0].point + "-Under " + itemState[0].prices[1].price, itemState?.[0]?.prices?.[1]?.option
                                    )}
                                >
                                    <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                        {(isInt((Math.abs(itemState[0].point)).toFixed(2)) ? (Math.abs(itemState[0].point)).toFixed(1) : (Math.abs(itemState[0].point)).toFixed(2))} - U
                                    </span>
                                    <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                        <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemState[0].prices[1].price).toFixed(2)}</b></p>
                                        <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[1]?.price, oddOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                    </div>
                                    {checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, awayTeam, "Fulltime OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                </div>
                                :
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                    <div className={`relative py-1 w-full flex justify-center`}>
                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={`justify-center w-full flex items-center`}>
                            <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex ${itemState && itemState.length > 0 ? "justify-end" : "justify-center"} w-full rounded-md relative`}>
                                <div className={`relative py-1 w-full flex justify-center`}>
                                    <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
                {itemState && itemState.length > 1 && itemState[1].prices && itemState[1].prices.length > 0
                    ?
                    <div className='flex justify-between w-full h-[110px] items-center border-dashed border-b-none border-[#202531]'>
                        <div className='w-full flex flex-col hdp gap-1'>
                            <div className={`justify-center w-full flex items-center `}>
                                {/* // Fulltime HDP */}
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[1]?.prices?.[0]?.price, oddOld.current?.[1]?.prices?.[0]?.price,
                                    () => (listChooseBet && odd.current?.[1]?.prices?.[0]?.price !== oddOld.current?.[1]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[1].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[1].id)].option === itemState?.[1]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[1].id, itemState[1].point + "-Over " + itemState[1].prices[0].price, itemState?.[1]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer
                                )} ${checkSelectSoccerNew_V2(1, 0, itemState[1]?.id ?? 0, listChooseBet, homeTeam, "Fulltime OU")}`}
                                    onClick={() => chooseBetTop?.(
                                        1, itemState[1].id, homeTeam, itemState[1].point + "-Over " + itemState[1].prices[0].price, itemState?.[1]?.prices?.[0]?.option
                                    )}
                                >
                                    <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                        {(isInt((Math.abs(itemState[1].point)).toFixed(2)) ? (Math.abs(itemState[1].point)).toFixed(1) : (Math.abs(itemState[1].point)).toFixed(2))} - O
                                    </span>
                                    <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                        <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemState[1].prices[0].price).toFixed(2)}</b></p>
                                        <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[1]?.prices?.[0]?.price, oddOld.current?.[1]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                    </div>
                                    {checkSelectSoccerNew_V2(1, 0, itemState[1]?.id ?? 0, listChooseBet, homeTeam, "Fulltime OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                </div>
                            </div>
                            <div className={`justify-center w-full flex items-center `}>
                                {itemState && itemState.length > 1 && itemState[1].prices && itemState[1].prices.length > 1
                                    ?
                                    <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[1]?.prices?.[1]?.price, oddOld.current?.[1]?.prices?.[1]?.price,
                                        () => (listChooseBet && odd.current?.[1]?.prices?.[1]?.price !== oddOld.current?.[1]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[1].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[1].id)].option === itemState?.[1]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[1].id, itemState[1].point + "-Under " + itemState[1].prices[1].price, itemState?.[1]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                                        dateFilterSoccer,
                                        listDateFilterSoccer
                                    )} ${checkSelectSoccerNew_V2(1, 0, itemState[1]?.id ?? 0, listChooseBet, awayTeam, "Fulltime OU")}`}
                                        onClick={() => chooseBetTop?.(
                                            1, itemState[1].id, awayTeam, itemState[1].point + "-Under " + itemState[1].prices[1].price, itemState?.[1]?.prices?.[1]?.option
                                        )}
                                    >
                                        <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                            {(isInt((Math.abs(itemState[1].point)).toFixed(2)) ? (Math.abs(itemState[1].point)).toFixed(1) : (Math.abs(itemState[1].point)).toFixed(2))} - U
                                        </span>
                                        <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                            <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemState[1].prices[1].price).toFixed(2)}</b></p>
                                            <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[1]?.prices?.[1]?.price, oddOld.current?.[1]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                        </div>
                                        {checkSelectSoccerNew_V2(1, 0, itemState[1]?.id ?? 0, listChooseBet, awayTeam, "Fulltime OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                    </div>
                                    :
                                    <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                        <div className={`relative py-1 w-full flex justify-center`}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={`justify-center w-full flex items-center`}>
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex ${itemState && itemState.length > 1 ? "justify-end" : "justify-center"} w-full rounded-md relative`}>
                                    <div className={`relative py-1 w-full flex justify-center`}>
                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    :
                    ""
                }
                {itemState && itemState.length > 2 && itemState[2].prices && itemState[2].prices.length > 0
                    ?
                    <div className='flex justify-between w-full h-[110px] items-center border-dashed border-b-none border-[#202531]'>
                        <div className='w-full flex flex-col hdp gap-1'>
                            <div className={`justify-center w-full flex items-center `}>
                                {/* // Fulltime HDP */}
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[2]?.prices?.[0]?.price, oddOld.current?.[2]?.prices?.[0]?.price,
                                    () => (listChooseBet && odd.current?.[2]?.prices?.[0]?.price !== oddOld.current?.[2]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[2].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[2].id)].option === itemState?.[2]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[2].id, itemState[2].point + "-Over " + itemState[2].prices[0].price, itemState?.[2]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer
                                )} ${checkSelectSoccerNew_V2(2, 0, itemState[2]?.id ?? 0, listChooseBet, homeTeam, "Fulltime OU")}`}
                                    onClick={() => chooseBetTop?.(
                                        2, itemState[2].id, homeTeam, itemState[2].point + "-Over " + itemState[2].prices[0].price, itemState?.[2]?.prices?.[0]?.option
                                    )}
                                >
                                    <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                        {(isInt((Math.abs(itemState[2].point)).toFixed(2)) ? (Math.abs(itemState[2].point)).toFixed(1) : (Math.abs(itemState[2].point)).toFixed(2))} - O
                                    </span>
                                    <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                        <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemState[2].prices[0].price).toFixed(2)}</b></p>
                                        <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[2]?.prices?.[0]?.price, oddOld.current?.[2]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                    </div>
                                    {checkSelectSoccerNew_V2(2, 0, itemState[2]?.id ?? 0, listChooseBet, homeTeam, "Fulltime OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                </div>
                            </div>
                            <div className={`justify-center w-full flex items-center`}>
                                {itemState && itemState.length > 2 && itemState[2].prices && itemState[2].prices.length > 1
                                    ?
                                    <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[2]?.prices?.[1]?.price, oddOld.current?.[2]?.prices?.[1]?.price,
                                        () => (listChooseBet && odd.current?.[2]?.prices?.[1]?.price !== oddOld.current?.[2]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[2].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[2].id)].option === itemState?.[2]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[2].id, itemState[2].point + "-Under " + itemState[2].prices[1].price, itemState?.[2]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                                        dateFilterSoccer,
                                        listDateFilterSoccer
                                    )} ${checkSelectSoccerNew_V2(2, 0, itemState[2]?.id ?? 0, listChooseBet, awayTeam, "Fulltime OU")}`}
                                        onClick={() => chooseBetTop?.(
                                            2, itemState[2].id, awayTeam, itemState[2].point + "-Under " + itemState[2].prices[1].price, itemState?.[2]?.prices?.[1]?.option
                                        )}
                                    >
                                        <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                            {(isInt((Math.abs(itemState[2].point)).toFixed(2)) ? (Math.abs(itemState[2].point)).toFixed(1) : (Math.abs(itemState[2].point)).toFixed(2))} - U
                                        </span>
                                        <div className={`relative py-1 border border-[#202531] w-[50%] cursor-pointer `}>
                                            <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemState[2].prices[1].price).toFixed(2)}</b></p>
                                            <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[2]?.prices?.[1]?.price, oddOld.current?.[2]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                        </div>
                                        {checkSelectSoccerNew_V2(2, 0, itemState[2]?.id ?? 0, listChooseBet, awayTeam, "Fulltime OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                    </div>
                                    :
                                    <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                        <div className={`relative py-1 w-full flex justify-center`}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={`justify-center w-full flex items-center`}>
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex ${itemState && itemState.length > 2 ? "justify-end" : "justify-center"} w-full rounded-md relative`}>
                                    <div className={`relative py-1 w-full flex justify-center`}>
                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    :
                    ""
                }
                {itemState && itemState.length > 3 && itemState[3].prices && itemState[3].prices.length > 0
                    ?
                    <div className='flex justify-between w-full h-[110px] items-center'>
                        <div className='w-full flex flex-col hdp gap-1'>
                            <div className={`justify-center w-full flex items-center `}>
                                {/* // Fulltime HDP */}
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[3]?.prices?.[0]?.price, oddOld.current?.[3]?.prices?.[0]?.price,
                                    () => (listChooseBet && odd.current?.[3]?.prices?.[0]?.price !== oddOld.current?.[3]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[3].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[3].id)].option === itemState?.[3]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[3].id, itemState[3].point + "-Over " + itemState[3].prices[0].price, itemState?.[3]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                                    dateFilterSoccer,
                                    listDateFilterSoccer
                                )} ${checkSelectSoccerNew_V2(0, 0, itemState[3]?.id ?? 0, listChooseBet, homeTeam, "Fulltime OU")}`}
                                    onClick={() => chooseBetTop?.(
                                        0, itemState[3].id, homeTeam, itemState[3].point + "-Over " + itemState[3].prices[0].price, itemState?.[3]?.prices?.[0]?.option
                                    )}
                                >
                                    <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                        {(isInt((Math.abs(itemState[3].point)).toFixed(2)) ? (Math.abs(itemState[3].point)).toFixed(1) : (Math.abs(itemState[3].point)).toFixed(2))} - O
                                    </span>
                                    <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                        <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemState[3].prices[0].price).toFixed(2)}</b></p>
                                        <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[3]?.prices?.[0]?.price, oddOld.current?.[3]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                    </div>
                                    {checkSelectSoccerNew_V2(0, 0, itemState[3]?.id ?? 0, listChooseBet, homeTeam, "Fulltime OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                </div>
                            </div>
                            <div className={`justify-center w-full flex items-center `}>
                                {itemState && itemState.length > 3 && itemState[3].prices && itemState[3].prices.length > 1
                                    ?
                                    <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[3]?.prices?.[1]?.price, oddOld.current?.[3]?.prices?.[1]?.price,
                                        () => (listChooseBet && odd.current?.[3]?.prices?.[1]?.price !== oddOld.current?.[3]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[3].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[3].id)].option === itemState?.[3]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[3].id, itemState[3].point + "-Under " + itemState[3].prices[1].price, itemState?.[3]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                                        dateFilterSoccer,
                                        listDateFilterSoccer
                                    )} ${checkSelectSoccerNew_V2(0, 0, itemState[3]?.id ?? 0, listChooseBet, awayTeam, "Fulltime OU")}`}
                                        onClick={() => chooseBetTop?.(
                                            0, itemState[3].id, awayTeam, itemState[3].point + "-Under " + itemState[3].prices[1].price, itemState?.[3]?.prices?.[1]?.option
                                        )}
                                    >
                                        <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                            {(isInt((Math.abs(itemState[3].point)).toFixed(2)) ? (Math.abs(itemState[3].point)).toFixed(1) : (Math.abs(itemState[3].point)).toFixed(2))} - U
                                        </span>
                                        <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                            <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemState[3].prices[1].price).toFixed(2)}</b></p>
                                            <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[3]?.prices?.[1]?.price, oddOld.current?.[3]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                        </div>
                                        {checkSelectSoccerNew_V2(0, 0, itemState[3]?.id ?? 0, listChooseBet, awayTeam, "Fulltime OU").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                    </div>
                                    :
                                    <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                        <div className={`relative py-1 w-full flex justify-center`}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={`justify-center w-full flex items-center`}>
                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex ${itemState && itemState.length > 3 ? "justify-end" : "justify-center"} w-full rounded-md relative`}>
                                    <div className={`relative py-1 w-full flex justify-center`}>
                                        <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    :
                    ""
                }
            </div>
        </>
    );
};

export default SoccerTopOUFNew;