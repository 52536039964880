import axios from "axios";
import qs from 'qs';
import * as ENV from "../env";
import tokenService from '../services/token';
export interface ResponseSuccessType<T> {
    success?: boolean;
    data: T;
    message?: string;
    type?: "nem" | "em"
}


export interface ResponseSuccessStores<T> {
    success?: boolean;
    data: T;
    message?: string;
    presentMoney?: string
    billCode?: string
}
export interface ResponseSuccess<T> {
    success?: boolean;
    data: T;
    message?: string;
}

export interface ResponseSuccessTotal<T> {
    success?: boolean;
    data: T;
    message?: string;
    total: number | 0
}

export interface ResponseError {
    success?: number,
    title: string,
    errors: {
        [key: string]: string[]
    }
}

const axiosClientLive = axios.create({
    baseURL: "",
    headers: {
        'content-type': 'multipart/form-data',
    },
    paramsSerializer: {
        serialize: params => {
            return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true });
        }
    }
});

axiosClientLive.interceptors.request.use(async (config) => {
    const token = tokenService.getTokenNew();
    if (token)
        config.headers.Authorization = 'Bearer ' + token;
    // config.headers.Authorization = 'Bearer ' + '12345678-abcd-1234-ef00-1234567890ab';
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosClientLive.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }
    return response;
}, (error) => {
    console.log(error)
    // if (error.response && error.response.data && error.response.data.error &&
    //     (error.response.data.session === false || error.response.data.session === "false")) {
    //     // alert("Đã xảy ra sự cố, sẽ tự động đăng xuất.");
    //     window.location.href = "/";
    // } else if (error?.response && error.response?.data && error.response.data.error && error.response.data.error.message) {
    //     return error?.response?.data
    // } else if (error.response && error.response.status === 500) {
    //     return error?.response?.data;
    // } else if (error?.response && error?.response?.status === 401) {
    //     return Promise.reject(error);
    // }
    // else if (error?.response && error?.response?.status === 403) {
    //     return Promise.reject(error)
    // }
    // else {
    //     return error?.response?.data;
    // }
    Promise.reject(error);
});

export default axiosClientLive;