import { soccer_2, cup, LogoWithoutText, liveIcon } from "../../../../components/imgExport"
import { formatPrice } from "../../../../until"
interface ItemProp {
    item?: any
    status: number
}
export const ItemSoccer: React.FC<ItemProp> = ({
    item,
    status
}) => {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return (
        <div >
            <div className="xs:hidden xl:flex w-full border-b border-b-[#202531] py-5">
                <div className="flex justify-center items-center w-[15%] ">
                    <div className="flex flex-col items-start gap-3">
                        <span className="text-sm text-[#E4E7F1]">{item.code}</span>
                        <span className="text-sm text-[#E4E7F1]">
                            {`${(new Date(item.date).toLocaleString('en', { timeZone: timeZone })).split(",")[0]}`}
                        </span>
                        <span className="text-sm text-[#E4E7F1]">
                            {`${(new Date(item.date).toLocaleString('en', { timeZone: timeZone })).split(",")[1]}`}
                        </span>
                    </div>
                </div>

                <div className="flex flex-col gap-2 items-start w-[55%] px-3 ">
                    {item.billDetails.map((itemBillDetail: any, indexBillDetail: any) => (
                        <>
                            <div className="flex items-center w-full h-full justify-between" key={indexBillDetail}>
                                <div className="flex flex-col gap-2 w-1/2">
                                    <div className="flex gap-3">
                                        <div >
                                            <img src={cup} alt="" className="h-5" />
                                        </div>
                                        <span className="text-sm text-[#E4E7F1]">{itemBillDetail.tournament}</span>
                                    </div>


                                    <div className="flex items-center gap-5 ">
                                        <div className='flex items-center gap-2'>
                                            <div className='w-[22px] h-[22px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                                <img src={LogoWithoutText} alt="NYKnicks" className='w-4 h-4' />
                                            </div>
                                            <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[0]}</span>
                                        </div>
                                        <span className='text-xs text-[#D4B962] pl-2'>VS</span>
                                        <div className='flex items-center gap-2'>
                                            <div className='w-[22px] h-[22px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                                                <img src={LogoWithoutText} alt="NYKnicks" className='w-4 h-4' />
                                            </div>
                                            <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[1]}</span>
                                        </div>
                                    </div>

                                    {itemBillDetail.stage === "1" || itemBillDetail.stage === 1 ?
                                        <>
                                            <div className="flex gap-4">
                                                <img src={liveIcon} alt="" />
                                                <span className="text-xs text-[#E4E7F1]">{`LIVE ${itemBillDetail.homeLiveScore ? itemBillDetail.homeLiveScore : 0} : ${itemBillDetail.awayLiveScore ? itemBillDetail.awayLiveScore : 0} `}</span>
                                            </div>
                                        </>
                                        :
                                        <span className="text-xs text-[#E4E7F1]">{`${(new Date(itemBillDetail.dateEvent).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                                    }
                                </div>

                                <div className="flex flex-col items-end gap-2 w-1/2">
                                    <div className="flex items-center  gap-2" key={indexBillDetail}>
                                        <img src={soccer_2} alt="" />
                                        <span className="text-xs text-[#C1C9E1] font-medium">{itemBillDetail.lineBetting}</span>
                                    </div>
                                    <div className="rounded-[4px] py-1 bg-[#212531]">
                                        <span className="px-3  text-sm text-[#E4E7F1]">{`${itemBillDetail.team} @ ${itemBillDetail.score}`}</span>
                                    </div>
                                </div>
                            </div>

                        </>
                    ))}
                </div>
                <div className="flex flex-col gap-2 items-center w-[10%]">
                    {item.billDetails.map((itemBillDetail: any, indexBillDetail: any) => (
                        <span className="flex items-center w-full h-full justify-center text-sm text-[#E4E7F1]" key={indexBillDetail}>{itemBillDetail.score.split(' ').length > 1 ? itemBillDetail.score.split(' ')[itemBillDetail.score.split(' ').length - 1] : itemBillDetail.score.split(' ')[0]}</span>

                    ))}
                </div>
                <div className="flex justify-center items-center w-[10%]">
                    <span className="text-sm text-[#D4B962]">${formatPrice(item.totalStake || 0)}</span>
                </div>
                <div className="flex justify-center items-center w-[10%]">
                    <span className={`text-sm ${(item.status === 0 && 'waiting text-[#D4B962]') || (item.status === 1 && 'text-[#33E49A]') || (item.status === 1 && 'text-[#33E49A]') || (item.status === 2 && 'text-[#3F8CFF]') || (item.status === 3 && 'text-[#FF4A59]')}`}>
                        {(item.status === 1 && 'RUNNING') || (item.status === 0 && 'WAITING') || (item.status === 3 && 'REJECT') || ('RUNNING')}
                        {/* {(status === 0 && 'WAITING') || ('RUNNING')} */}
                    </span>
                </div>
            </div>
            <div className="xs:flex xl:hidden flex-col gap-3 rounded-xl border border-[#202531] p-3">
                <div className="flex justify-between items-center">
                    <span className="text-sm text-[#E4E7F1]">{item.code}</span>
                    <span className="text-sm text-[#E4E7F1]">{`${(new Date(item.date).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                </div>



                {item.billDetails.map((itemBillDetail: any, indexBillDetail: any) => (
                    <>
                        <div className="flex items-center gap-2" key={indexBillDetail}>
                            <img src={soccer_2} alt="" />
                            <span className="text-xs text-[#C1C9E1] font-medium">{itemBillDetail.lineBetting}</span>
                        </div>
                        <div className="rounded-[4px] py-1 bg-[#212531] w-full">
                            <span className="px-3  text-sm text-[#E4E7F1]">{`${itemBillDetail.team} @ ${itemBillDetail.score}`}</span>
                        </div>

                        <div className='flex items-center gap-4'>
                            <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                            </div>
                            <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[0]}</span>
                        </div>
                        <div className='flex items-center gap-4'>
                            <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                            </div>
                            <span className='text-[#E4E7F1] fw-[500] text-xs'>{itemBillDetail.game.split('/')[1]}</span>
                        </div>
                        {/* thêm phần tên đội nhà , đội khách, tournament, thời gian diễn ra trận đấu */}
                        <div className="flex items-center justify-between">
                            <span className="text-sm font-bold-GT-America uppercase text-[#E4E7F1]">tournament</span>
                            <span className="text-sm text-[#E4E7F1]">{itemBillDetail.tournament}</span>
                        </div>


                        {itemBillDetail.stage ?
                            <div className="flex items-center justify-between">
                                <span className="text-sm font-bold-GT-America uppercase text-[#E4E7F1]">Live</span>
                                <span className="text-sm text-[#E4E7F1]">{`${itemBillDetail.homeLiveScore} : ${itemBillDetail.awayLiveScore}`}</span>
                            </div> :
                            <div className="flex items-center justify-between">
                                <span className="text-sm font-bold-GT-America uppercase text-[#E4E7F1]">date event</span>
                                <span className="text-sm text-[#E4E7F1]">{`${(new Date(itemBillDetail.dateEvent).toLocaleString('en', { timeZone: timeZone }))}`}</span>
                            </div>
                        }

                        <div className="flex items-center justify-between">
                            <span className="text-sm font-bold-GT-America uppercase text-[#E4E7F1]">odds</span>
                            <span className="text-sm text-[#E4E7F1]">{itemBillDetail.score.split(' ').length > 1 ? itemBillDetail.score.split(' ')[itemBillDetail.score.split(' ').length - 1] : itemBillDetail.score.split(' ')[0]}</span>
                        </div>
                    </>
                ))}



                <div className="flex items-center justify-between">
                    <span className="text-sm font-bold-GT-America uppercase text-[#E4E7F1]">stake</span>
                    <span className="text-sm text-[#D4B962]">${item.totalStake}</span>
                </div>
                <div className="flex items-center justify-between">
                    <span className="text-sm font-bold-GT-America uppercase text-[#E4E7F1]">Status</span>
                    <span className={`text-sm ${(status === 0 && 'waiting text-[#D4B962]') || (status === 1 && 'text-[#33E49A]') || (status === 2 && 'text-[#3F8CFF]') || (status === 3 && 'text-[#FF4A59]')}`}>
                        {(status === 1 && 'RUNNING') || (status === 0 && 'WAITING') || (status === 3 && 'REJECT') || ('RUNNING')}
                    </span>
                </div>

            </div>
        </div >


    )
}