import { Checkbox, Drawer } from "antd"
import * as homePage_V2Api from '../../api/homePage/homePage_V2Api'
import { CloseCircleOutlined } from "@ant-design/icons"
import { leaf_1, leaf_2 } from "../imgExport"
import './style.css'
interface DrawerProp {
    openDrawer: boolean
    dataTournamentSameSport: homePage_V2Api.InfoTournamentSameSport[]
    listChooseTournament?: number[]
    chooseTournament?: (tournament: string, key: number) => void
    clearTournament?: () => void
    closeDrawer: (value: boolean) => void
}
export const DrawerTourNameMGMMobile: React.FC<DrawerProp> = ({
    openDrawer, dataTournamentSameSport, listChooseTournament,
    chooseTournament, clearTournament, closeDrawer
}) => {
    return (
        <Drawer
            open={openDrawer}
            onClose={() => closeDrawer(false)}
            closeIcon={false}
            placement="bottom"
            className="!bg-[#131620] p-none-drawer xs:!block xl:!hidden p-none-header"
            maskClassName="xs:!block xl:!hidden"
            title={
                <div className="flex justify-between items-center bg-gradient-A98F34 w-full pl-2 pr-5 py-2">
                    <div className=" flex justify-start"><span className='text-sm uppercase font-medium p-1 rounded bg-[#DBBF64] text-[#131620] ' onClick={(e) => clearTournament && clearTournament()}>clear all  </span></div>
                    <div className=' py-2 flex justify-center items-center gap-2  '>
                        <img src={leaf_1} alt="" className="h-[18px]" />
                        <span className='text-sm uppercase  fw-[400] text-[#E4E7F1] '>tournament EVENT</span>
                        <img src={leaf_2} alt="" className="h-[18px]" />
                    </div>
                    <div className=" flex justify-end">
                        <CloseCircleOutlined
                            className="text-red-600 cursor-pointer text-[24px]"
                            onClick={() => closeDrawer(false)}
                        />
                    </div>
                </div>
            }
        >
            <div className="flex flex-col gap-2 pl-6 pr-2 pt-6 w-full overflow-y-scroll">
                {dataTournamentSameSport.map((item, index) => (
                    <div
                        key={index}
                        className={`flex items-center justify-between gap-2 pb-2 !uppercase  ${listChooseTournament && listChooseTournament.includes(item.id_tournament) ? '!text-[#D4B962]' : 'text-white'}`}
                    >
                        {item.tournament}
                        <Checkbox
                            checked={listChooseTournament && listChooseTournament.includes(item.id_tournament)}
                            onClick={e => chooseTournament && chooseTournament(item.tournament, item.id_tournament)}
                            className="!text-white custom_checkbox"
                        />
                    </div>

                ))}

            </div>
        </Drawer>
    )
}